


.c-categorysubselector {

  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow-y: hidden;
  position: relative;
  margin-bottom: 20px;

  margin-left: calc(var(--bs-gutter-x) * -1);
  margin-right: calc(var(--bs-gutter-x) * -1);

  &:before {
    content: '';
    z-index: 3;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    width: 10px;

    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

  }

  &:after {
    content: '';
    z-index: 3;
    position: absolute;
    right:0;
    top:0;
    bottom:0;
    width: 10px;

    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

  }

  .c-categorysubselector__wrapper {
    padding-bottom: 30px;
    margin-bottom: -30px;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right:20px
  }

  a {
    text-decoration: none;
    display: block;
    font-size: 14px;
    color: #333;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 18px 0 10px 0;
    margin-right: 10px;
    margin-left: 10px;
    border-bottom: 3px solid transparent;
    white-space: nowrap;

    &.is-selected {
      font-weight: 600;
      border-bottom: 3px solid $accentColor;
    }
  }

}

@include media-breakpoint-up(md) {
  .c-categorysubselector {
    display: none;
  }
}
