

.c-shipping-method {

  &.is-selected {

    .c-shipping-method__point {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 10px;
    }

  }

  .c-shipping-method__old_price {
    font-size: 12px;
    color: #999;
    text-decoration: line-through;
  }
}
