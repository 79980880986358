/**
MAIN MENU component
*/

$cMenuHeight: 100%;

.c-menu {
  display: none;
}

@include media-breakpoint-down(lg) {

  .c-mobile-menu-opened {
    overflow: hidden;

    &::before {
      content: '';
      position: fixed;
      top:0;
      bottom:0;
      left:0;
      right:0;
      background-color: rgba(0,0,0,0.6);
      z-index: 500;
    }

    .c-menu {
      display: block !important;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
      max-width: 100%;
      width: 600px;
      background-color: #fff;
      padding: 20px;
      z-index: 600;
      transition: all 0.3s ease-in-out;

      transform: translateX(-100%);

      .top-panel-links {

        .top-panel-link {

          color: #333;
          font-weight: 400;
          font-size: 16px;
          border-bottom: 1px solid #eee;

          a {
            display: block;
            padding: 10px 10px;
            color: #333;
          }
        }

      }

      //resetcontainer
      .container {
        padding: 0;
        margin: 0;
      }

      &.c-menu-opened {
        transform: translateX(0);
      }

      .c-menu__close_mobile {
        float: right;

      }

      a {
        text-decoration: none;
      }

      .c-menu__link {
        display: block;
      }

      ul.c-menu__list {
        list-style: none;
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0;
      }

      .c-menu__item {
        position: relative;
        .open-ind {
          color: #666;
          pointer-events: none;
          position: absolute;
          top: 14px;
          right: 10px;
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          transition: all 0.3s ease-in-out;
        }

        &.c-menu__item-opened {
          > a .open-ind {
            transform: rotate(270deg);
          }
        }
      }

      .c-menu__dropdown.is-level1 {
        display: none;
        margin-left: 20px;

        .c-menu__item.is-level1 {
          border-bottom: 1px solid #ddd;

          &:last-child {
            border-bottom: 0;
          }

          &.c-menu__item-opened {
            background-color: rgba(50,50,50, 0.02);

            .c-menu__list.is-level2 {
              display: block;
            }
          }
        }

        .c-menu__link.is-level-link1 {
          color: #333;
          font-weight: 500;
          font-size: 16px;
          padding: 10px 10px;
        }


      }

      .c-menu__list.is-level2 {
        display: none;

        padding-left:10px;
        .c-menu__item.is-level2 {
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: 0;
          }
        }

        .c-menu__link.is-level-link2 {
          padding: 10px 10px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
        }

      }

      .c-menu__list.is-level0 {

        .c-menu__item.is-level0 {
          padding: 10px 10px;
          border-bottom: 1px solid #eee;

          &.c-menu__item-opened {
            background-color: rgba(50,50,50, 0.02);
            .c-menu__dropdown.is-level1 {
              display: block;
            }
          }

        }

        .c-menu__item.is-level0.is-promo {
          .c-menu__link.is-level-link0 {
            color: $accentColor;
          }
        }

        .c-menu__link.is-level-link0 {
          color: #333;
          font-weight: 700;
          font-size: 16px;
        }

      }

    }

    .c-menu__dropdown__popular_brands {
      border-top: 1px solid #ddd;
      padding: 10px;

      .c-menu__dropdown__popular_brands__title {
        margin-bottom: 10px;
      }

      .c-menu__dropdown__popular_brands__list_item {
        margin-right: 10px;
        width: 80px;
        height: 80px;

        border: 1px solid #eee;
        display: inline-flex;
        align-items: center;
        text-align: center;
        vertical-align: top;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

    }
  }



}