.c-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 0;

  background-color: rgba(0,0,0,0.6);

  .c-modal-name {
    font-weight: bold;
    margin-bottom: 10px;

    .c-modal-name__close {
      float: right;
      cursor: pointer;

      &:hover {
        svg{
          color: #333;
        }
      }
      svg {
        width: 24px;
        height: 24px;
        color: #aaa;
        pointer-events: none;
      }
    }
  }

  .c-modal-content {
    margin-left: auto;
    margin-right: auto;

    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
  }

  .c-modal-buttons {
    margin-top: 10px;
    .c-button {
      margin: 5px;
    }
  }

  &.is-narrow {
    .c-modal-content {
      width: 500px;
      max-width: 100%;
    }
  }

}

@include media-breakpoint-up(md) {
  .c-modal {
    padding-top: 2%;
    .c-modal-content {
      width: 75%;
    }


  }
}

@include media-breakpoint-up(lg) {
  .c-modal {
    padding-top: 5%;

    .c-modal-content {
      width: 50%;
    }
  }
}