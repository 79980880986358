
.c-breadcrumbs {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  font-weight: 500;

  a {
    color: #333;
    text-decoration: none;
  }

  span {
    color: #888;
  }

  svg {
    display: inline-block;
    height: 0.9em;
    width: 0.9em;
    margin: 0 6px;
    vertical-align: -2px;
    color: #aaa;
  }
}