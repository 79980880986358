
.c-progress {
  margin: 5px 0;
  display: block;
  height: 12px;
  background-color: #ddd;

  .c-progress__bar {
    background-color: #198754;
    height: 12px;
  }
}