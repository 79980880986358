@import "common_section";
@import "categories_section";

@import "elements/category-cell";
@import "elements/product-cell";

@import "elements/breadcrumbs";
@import "elements/category-tree";
@import "elements/categorysubselector";
@import "elements/product-filters";
@import "elements/product-images";
@import "elements/micro_product";
@import "elements/products-paginator";
//headers


.module-heading {
  font-size: 12px;
  padding-bottom: 1.8em;
  position: relative;

  .logo {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 1.8em);

    img {
      max-height: 100%;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 2.3em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  h2 {
    font-weight: 700;
    font-size: 1.6em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
  }

  .brand-sub {
    font-weight: 500;
    color: #222;
    font-size: 1.4em;

  }

  .sub {
    font-size: 1.9em;
    font-weight: 600;
    color: #666;
  }

  .module-desc {
    font-size: 1em;
    color: #666;

    p {
      margin-bottom: 0.4em;
    }
  }
}

h4 {
  font-size: 16px;
  font-weight: 600;
}

@include media-breakpoint-up(lg) {

  .module-heading {
    font-size: 14px;
  }


  h2.module-heading, .module-heading h2 {
    font-size: 24px;

  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

}