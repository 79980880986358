/**
MAIN MENU component
*/

$cMenuHeight: 100%;


@import "header-menu-mobile";

@include media-breakpoint-up(lg) {

  .c-menu {
    position: static;
    border-top: 1px solid #eee;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;

    .is-show-all-link, .top-panel-links, .open-ind {
      display: none;
    }

    a {
      text-decoration: none;
    }

    ul.c-menu__list {
      list-style: none;
      margin-left: 0;
      margin-bottom: 0;
      padding-left: 0;
    }

    /**
    level 0
     */
    .c-menu__list.is-level0 {



      display: flex;
      flex-direction: row;
      list-style: none;

      .c-menu__item.is-level0 {
        height: $cMenuHeight;
        overflow: visible;
        flex: 0 0 auto;
        min-height: 0;
        min-width: 0;
        width: auto;
        padding: 0 20px;
        border-bottom: 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .c-menu__item.is-level0.is-promo {
        .c-menu__link.is-level-link0 {
          color: $accentColor;
        }
      }

      .c-menu__link.is-level-link0 {
        position: relative;
        display: flex;
        align-items: center;
        height: $cMenuHeight;
        text-align: center;
        padding: 0px 10px;
        width: auto;

        color: #333;
        font-weight: 700;
        font-size: 16px;
        line-height: 2em;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 3px;
          background-color: $accentColor;
          top: calc(100% - 3px);
          left: 0px;

          transition: all 0.2s ease-in-out;
        }


      }
    }

    .c-menu__dropdown.is-level1 {
      position: absolute;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      background-color: #fff;

      //padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #eee;

      .c-menu__list.is-level1 {
        //width: 72%;
      }


    }

    .c-menu__item.is-level0:hover {

      .c-menu__link.is-level-link0 {
        &::after {
          width: 100%;
        }
      }

      .c-menu__dropdown.is-level1 {
        z-index: 50;
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        &:before {
          content: '';
          position: absolute;
          top: calc(100% - 10px);
          width: 100%;
          height: 20px;
          background-color: #fff;
          z-index: 2;
        }

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          width: 100%;
          height: 5px;
          box-shadow: 0px 0px 8px 2px #000000;
          z-index: -1;
        }
      }
    }


    .c-menu__list.is-level1 {

      margin-top: 20px;

      .c-menu__item.is-level1 {
        width: 33.3333333%;
        //width: 50%;
        float: left;

        & + .c-menu__item.is-level1 {

        }

      }

      .c-menu__link.is-level-link1 {
        color: #222;
        font-size: 16px;
        font-weight: 600;
        line-height: 2em;

        &:hover {
          color: $accentColor;
        }
      }


    }

    .c-menu__list.is-level2 {
      margin-left: 12px;

      .c-menu__item.is-level2 {

      }

      .c-menu__link.is-level-link2 {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        line-height: 2.1em;

        &:hover {
          color: $accentColor;
        }
      }

    }

  }


  .c-menu {
    .c-menu__dropdown__popular_brands {
      margin-top:20px;


      .c-menu__dropdown__popular_brands__title {
        font-size: 16px;
        font-weight: 600;
        color: #222;
        margin-bottom: 10px;
      }

      .c-menu__dropdown__popular_brands__list_item {
        display: flex;
        width: 80px;
        height: 80px;
        border: 1px solid #eee;
        align-items: center;
        justify-content: center;
        margin: 0 10px 10px 0;
        float: left;
        img {
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {

        }


      }



    }


  }

}
