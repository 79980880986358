
.c-cart {
  margin-top: 40px;

  .c-cart__item {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;

    .loader {
      display: none;
    }

    &.is-loading {

      .loader {
        display: block;
        position: absolute;
        background-color: rgba(255,255,255,0.6);
        top:0;
        left:0;
        right:0;
        bottom:0;

        &:after {
          position: absolute;
          top:50%;
          left:50%;
          content:'';
          color: #222;
          font-size: 6px;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          text-indent: -9999em;

          animation: load4 1.3s infinite linear;

          transform: translateZ(0);
          display: block;
        }

      }

    }

    .c-cart__item_qunat_wrap {
      margin-top: 10px;
    }

    .c-cart__price_wrap {
      text-align: right;
      margin-top: 10px;
    }

    .c-cart__item__price {
      font-size: 18px;
      color: #222;


      b, strike {
        white-space: nowrap;
      }
      .c-cart__item__price__old {
        font-size: 14px;
        color: #999;
        text-decoration: line-through;
      }

      .c-cart__item__price__quantity {
        font-size: 14px;
        color: #666;
      }

    }

    .c_cart__item__remove {

      display: inline-block;
      cursor: pointer;
      margin-left: 10px;
      vertical-align: 4px;

      &:hover {
        color: #333;
      }

      svg {
        color: #999;
        pointer-events: none;
        width: 24px;
        height: 24px;
      }
    }

    .c-cart__item__label {
      font-size: 18px;
      font-weight: 500;
      color: #222;

      &:hover {
        text-decoration: underline;
      }
    }

    .c-cart__item__sublabel {
      font-size: 14px;
      color: #888;
      font-weight: 400;
    }

    .c-cart__item__tag {
      font-size: 13px;
      font-weight: 400;
      padding: 0.3rem 0.7rem;
      background-color: #676767;
      color: #fff;
      display: inline-block;
    }

  }
}

@include media-breakpoint-up(md) {
  .c-cart {
    .c-cart__item {

      .c-cart__item_qunat_wrap {
        text-align: right;
      }

      .c-cart__item__price {
        margin-top: 10px;
      }
    }
  }
}