//reset-button styles
button.c-button, input[type="submit"].c-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  //padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}

a.c-button {
  text-decoration: none;
}


.c-button {

  display: inline-block;
  color: #222;
  background-color: #dedede;
  font-size: 14px;
  text-align: center;
  padding: 10px 30px;

  cursor: pointer;
  user-select: none;

  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;

  .ionicon {
    vertical-align: -6px;
    margin-right: 6px;
  }

  &:hover {
    background-color: #ededed;
    color: #000;
  }

  &.is-small {
    font-size: 12px;
    padding: 8px 20px;
    font-weight: 400;
    .ionicon {
      vertical-align: -8px;
      margin-right: 6px;
    }

  }

  &.is-dark {
    border: 2px solid #333;
    background-color: #333;
    color: #eee;
    &:hover {
      background-color: #111;
      color: #fff;
    }
  }

  &.is-outline {
    border: 2px solid #222;
    background-color: transparent;
    &:hover {
      background-color: #222;
      color: #fff;
    }
  }

  &.is-gray-outline {
    color: #666;
    border: 2px solid #666;
    background-color: transparent;
    &:hover {
      background-color: transparent;
      color: #333;
      border: 2px solid #333;

    }
  }

  &.is-white-outline {
    color: #fff;
    border: 2px solid #fff;
    background-color: transparent;
    &:hover {
      background-color:#fff;
      color: #222 ;
    }
  }

  &.is-block {
    display: block;
    width: 100%;
  }

  &.is-primary {
    color: #fff;
    background-color: $accentColor;
    &:hover {
      background-color: $accentDarkColor;
    }
  }
}



.c-button.is-loading {
  text-indent: -999999px;
  position: relative;

  &:after {
    position: absolute;
    top:50%;
    left:50%;
    content:'';
    color: #ffffff;
    font-size: 4px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;

    animation: load4 1.3s infinite linear;

    transform: translateZ(0);
    display: block;
  }

}

