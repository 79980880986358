
.c-cell-product {

  font-size:14px;
  flex: 1 1 auto !important;
  padding: 1em;
  //border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eee;
  position: relative;

  &.is-unavailable {
    .c-cell-product__img {
      opacity: 0.5;
    }

    .c-cell-product__unavailable {
      font-size: 12px;
      color: #666;
      font-weight: 600;
    }
  }

  .c-cell-product__addcart {
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    color: #333;
    cursor: pointer;
    margin-top: 0.6rem;
    background-color: #eee;
    border: 1px solid #eee;
    padding: 0.5rem 0.7rem;
    text-align: center;

    .c-cell-product__addcart-lbl {
      font-size: 13px;
      font-weight: 500;
      display: inline-block;

    }

    svg {
      width: 28px;
      height: 28px;
    }

    &:hover {
      background-color: #333;
      color: #fff;
      border: 1px solid #333;

    }
  }

  &:hover {
    .c-cell-product__addcart {
      visibility: visible;
      opacity: 1;
    }
  }

  a {
    text-decoration: none;
  }

  .c-cell-product__img {
    width: 100%;
    flex-basis: 100%;
    flex-shrink: 0;
    display: flex;
    height: 160px;
    align-items: center;
    text-align: center;
    overflow: hidden;

    img {
      max-height: 140px;
      max-width: 100%;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-cell-product__name {

    .c-cell-product__name__name {
      font-weight: 600;
      font-size: 1em;
      color: #333;
    }

    .c-cell-product__name_sub {
      display: block;
      color: #666;
      font-size: 0.9em;
    }

    .c-cell-product__name__price {
      font-size: 1.1em;
      color: #333;
      font-weight: 600;
      margin-top: 0.5em;

      &.is-discount {
        color: #ca0a0a;
      }

      .c-cell-product__name__price_old {
        color: #999;
        display: inline-block;
        margin-left: 10px;
        text-decoration: line-through;
        font-weight: normal;
      }

    }
  }

  &:hover {
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  }

}


@include media-breakpoint-up(md) {
  .c-cell-product {
    font-size: 16px;

    .c-cell-product__img {
      height: 220px;

      img {
        max-height: 200px;
      }
    }
  }
}
