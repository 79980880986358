$accentColor: #b12121 !default;

.comp-header-cart {

  .comp-header-cart__counter {
    position: absolute;
    font-size: 10px;
    background-color: $accentColor;
    color: #fff;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    border-radius: 50%;
    line-height: 17px;
    text-align: center;
    border: 2px solid #fff;
  }

}

.c-cart-dropdown {
  padding: 10px;

  .c-cart-dropdown__item {
    padding: 10px;
    margin-bottom: 0;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #fafafa;
    }
  }

  .c-cart-dropdown__item + .c-cart-dropdown__item{
    border-top: 1px solid #eee;
  }

  .c-cart-dropdown__bottom {
    border-top: 1px solid #eee;
    padding-top: 10px;
    text-align: right;
  }
}