.c-details {
  margin-top: 20px;

  .c-details__header {
    padding: 10px;
    border-top: 1px solid #eee;
    font-size: 16px;
    font-weight: 600;
  }

  .c-details__param {
    font-size: 16px;
    line-height: 1.6em;
    margin: 10px 10px;

    .c-details__param_name {
      display: block;
      font-size: 12px;
      color: #666;
    }
  }
}

@include media-breakpoint-up(md) {
  .c-details {

    .c-details__header {
      border-top: 0;
      border-bottom: 1px solid #eee;
      padding: 8px 14px 10px 14px;
      font-size: 14px;


      cursor: pointer;
    }

    .c-details__param {
      font-size: 14px;
      line-height: 1.6em;
      margin: 8px 14px;

      .c-details__param_name {
        display: block;
        font-size: 12px;
        color: #666;
      }
    }
  }

  @include media-breakpoint-up(md) {

  }
}