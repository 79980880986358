.c-tabs {

  margin-top: 20px;
  border-bottom: 1px solid #eee;
  .c-tabs__list {
    display: none;
  }

  .c-tabs__content {

    .c-tabs__content_acc {
      position: relative;
      padding: 10px;
      border-top: 1px solid #eee;
      font-size: 16px;
      font-weight: 600;

      span {
        display: block;
        pointer-events: none;
      }

      svg {
        position: absolute;
        right: 10px;
        height: 16px;
        width: 16px;
        top: 12px;
        transform: rotate(90deg);
        color: #333;
        pointer-events: none;
      }

      &.is-opened {
        svg {
          transform: rotate(270deg);
        }
      }

    }

    .c-tabs__content_item {
      font-size: 13px;
      transition: all 0.3s ease-in-out;
      max-height: 0px;
      overflow: hidden;

      .c-tabs__content_item_wrapper {
        padding: 10px 10px;
      }

    }
  }
}

@include media-breakpoint-up(md) {
  .c-tabs {

    .c-tabs__list {
      display: block;
      border-bottom: 1px solid #eee;

      .c-tabs__list_item {
        display: inline-block;
        padding: 8px 14px;
        font-size: 14px;

        border-bottom: 2px solid transparent;
        cursor: pointer;
        &.is-selected {
          border-bottom: 2px solid $accentDarkColor;
          font-weight: bold;
        }

        &:hover {
          border-bottom: 2px solid $accentColor;
        }
      }

    }

    .c-tabs__content {
      .c-tabs__content_acc {
        display: none;
      }

      .c-tabs__content_item {
        font-size: 14px;
        display: none;
        margin-top: 20px;

        max-height: none;
        overflow: auto;

        &.is-selected {
          display: block;
        }
      }


    }
  }
}