


.c-categorytree {

  ul {
    padding-left: 15px;
  }

  a {
    text-decoration: none;
  }
  font-size: 14px;

  ul.level0 {
    margin-left: 0;
    padding-left: 0;
  }

  a {
    color: #444;
    line-height: 20px;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 5px 0;
  }

  li.is-selected {
    font-weight: 600;
  }

  .is-selected-leaf {
    color: $accentColor;
  }

}