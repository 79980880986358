.c-search-term-filter {
  padding: 10px;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 0px;
  border: 1px solid #aaa;
  color: #333;
  cursor: pointer;
  .c-search-term-filter__term {
    font-weight: 700;
  }

  .c-search-term-filter__remove {
    svg {
      width: 16px;
      height: 16px;
      vertical-align: -3px;
    }
  }

  &:hover {
    text-decoration: line-through;
  }
}

