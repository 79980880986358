.comp-reldrop {
  position: relative;

  .c-reldrop__dropdown {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    right: 0;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.2);
    z-index: 600;
  }


}

@include media-breakpoint-up(lg) {
  .comp-reldrop {
    &:hover {
      .c-reldrop__dropdown {

        //transform: translateY(100%);
        visibility: visible;
        pointer-events: all;

        opacity: 1;
      }
    }
  }
}