@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }
}
@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
body {
  background-color: #fff;
}

button.c-button, input[type=submit].c-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}

.ionicon {
  height: 24px;
  width: 24px;
  color: #333;
}

.iconlink {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 4px;
  padding-right: 4px;
}
.iconlink svg {
  color: #333;
  height: 32px;
  width: 32px;
}
.iconlink:hover svg {
  color: #bc1c1c;
}

.svgbutton {
  border: 0;
  background: none;
  outline: 0;
}
.svgbutton svg {
  color: #333;
  height: 32px;
  width: 32px;
}

button.c-button, input[type=submit].c-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}

a.c-button {
  text-decoration: none;
}

.c-button {
  display: inline-block;
  color: #222;
  background-color: #dedede;
  font-size: 14px;
  text-align: center;
  padding: 10px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
}
.c-button .ionicon {
  vertical-align: -6px;
  margin-right: 6px;
}
.c-button:hover {
  background-color: #ededed;
  color: #000;
}
.c-button.is-small {
  font-size: 12px;
  padding: 8px 20px;
  font-weight: 400;
}
.c-button.is-small .ionicon {
  vertical-align: -8px;
  margin-right: 6px;
}
.c-button.is-dark {
  border: 2px solid #333;
  background-color: #333;
  color: #eee;
}
.c-button.is-dark:hover {
  background-color: #111;
  color: #fff;
}
.c-button.is-outline {
  border: 2px solid #222;
  background-color: transparent;
}
.c-button.is-outline:hover {
  background-color: #222;
  color: #fff;
}
.c-button.is-gray-outline {
  color: #666;
  border: 2px solid #666;
  background-color: transparent;
}
.c-button.is-gray-outline:hover {
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
}
.c-button.is-white-outline {
  color: #fff;
  border: 2px solid #fff;
  background-color: transparent;
}
.c-button.is-white-outline:hover {
  background-color: #fff;
  color: #222;
}
.c-button.is-block {
  display: block;
  width: 100%;
}
.c-button.is-primary {
  color: #fff;
  background-color: #b12121;
}
.c-button.is-primary:hover {
  background-color: #760c0c;
}

.c-button.is-loading {
  text-indent: -999999px;
  position: relative;
}
.c-button.is-loading:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  color: #ffffff;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0);
  display: block;
}

.u-checkbox {
  position: relative;
  padding-left: 30px;
  display: block;
}
.u-checkbox input[type=checkbox] {
  display: none;
}
.u-checkbox input[type=checkbox] + div {
  position: absolute;
  left: 0;
}
.u-checkbox input[type=checkbox] + div > svg {
  color: #999;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  line-height: 24px;
  vertical-align: -7px;
  display: inline-block;
}
.u-checkbox input[type=checkbox] + div > svg.checked {
  display: none;
}
.u-checkbox input[type=checkbox]:checked + div > svg.unchecked {
  display: none;
}
.u-checkbox input[type=checkbox]:checked + div > svg.checked {
  display: inline-block;
}

.c-progress {
  margin: 5px 0;
  display: block;
  height: 12px;
  background-color: #ddd;
}
.c-progress .c-progress__bar {
  background-color: #198754;
  height: 12px;
}

.c-shadow-block {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}
.c-shadow-block .c-shadow-block__header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.c-shadow-block .c-shadow-block__errors {
  list-style: none;
  padding: 10px;
  font-weight: 600;
  background-color: #b80000;
  margin: 10px 0;
  color: #fff;
}

@media (min-width: 768px) {
  .c-shadow-block {
    padding: 20px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .c-shadow-block + .c-shadow-block {
    margin-top: 30px;
  }
}
.u-global-error {
  padding: 10px;
  border: 1px solid #b80000;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 2em;
  background-color: #b80000;
  color: #fff;
  font-weight: 600;
}

header .top-panel {
  font-size: 0.8rem;
  background-color: #f8f9fa;
  padding: 0.8rem 0;
}
header .top-panel a {
  color: #222;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
header .top-panel a:hover {
  color: #b12121;
}
header .top-panel a:hover .ionicon {
  color: #b12121;
}
header .top-panel a .ionicon {
  vertical-align: -6px;
  height: 22px;
}

header .nav-panel .nav-panel__logo-link {
  display: block;
  padding-bottom: 10px;
  padding-top: 10px;
}
header .nav-panel .nav-panel__logo-link img {
  height: 60px;
}
header .nav-panel .nav-panel__main {
  display: flex;
  flex-direction: column;
}
header .nav-panel .nav-panel__main > * {
  flex-basis: 100%;
}
header .nav-panel .nav-panel__main > * > .row {
  height: 100%;
}

@media (min-width: 992px) {
  header .nav-panel .nav-panel__logo-link img {
    height: 120px;
  }
  header .nav-panel .nav-panel__main .nav-panel__menu {
    border-top: 1px solid #eee;
  }
  header .nav-panel .nav-panel__main > * {
    flex-basis: 50%;
  }
}
header {
  border-bottom: 1px solid #ebeef3;
}

.c-cart-menu-footer {
  background-color: #fafafa;
  padding: 1rem 0;
}
.c-cart-menu-footer a {
  color: #666;
}
.c-cart-menu-footer a .ionicon {
  width: 16px;
  height: 16px;
  color: #666;
  vertical-align: -0.18em;
}
.c-cart-menu-footer a:hover {
  color: #000;
}
.c-cart-menu-footer a:hover .ionicon {
  color: #000;
}

.layout-footer {
  margin-top: 20px;
}

.footer-container {
  font-size: 16px;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #f5f5f5;
}

.footer-block {
  margin-top: 1em;
  margin-bottom: 2em;
  line-height: 2em;
}
.footer-block.is-collapsible {
  margin-top: -1px;
  border-top: 1px solid #ddd;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}
.footer-block.is-collapsible .footer-block__content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.footer-block.is-collapsible .footer-block__header {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.6em;
  position: relative;
}
.footer-block.is-collapsible .footer-block__header .open-ind {
  position: absolute;
  height: 16px;
  width: 16px;
  right: 10px;
  top: 5px;
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}
.footer-block.is-collapsible.is-opened .footer-block__header .open-ind {
  transform: rotate(270deg);
}
.footer-block .footer-block__header {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.1em;
}
.footer-block .footer-block__content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.footer-block .footer-block__content a {
  color: #333;
  text-decoration: none;
}
.footer-block .footer-block__content a:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  footer {
    font-size: 14px;
  }

  .footer-block.is-collapsible {
    margin-bottom: 1em;
    padding: 0;
    border: 0;
  }
  .footer-block.is-collapsible .footer-block__content {
    max-height: none;
    overflow: visible;
  }
  .footer-block.is-collapsible .footer-block__header {
    font-size: 1.4em;
    margin-bottom: 0.2em;
  }
  .footer-block.is-collapsible .footer-block__header .open-ind {
    display: none;
  }
}
.footer-icon-block {
  padding: 5px 0;
  display: flex;
}
.footer-icon-block .ionicon {
  margin-right: 10px;
  margin-top: 2px;
  width: 24px;
  height: 24px;
}

.footer-social-links {
  margin-top: 0.4em;
}
.footer-social-links .footer-social-links__link {
  margin-right: 32px;
}
.footer-social-links .footer-social-links__link:hover {
  text-decoration: none !important;
}
.footer-social-links .ionicon {
  width: 32px;
  height: 32px;
}

/**
MAIN MENU component
*/
/**
MAIN MENU component
*/
.c-menu {
  display: none;
}

@media (max-width: 991.98px) {
  .c-mobile-menu-opened {
    overflow: hidden;
  }
  .c-mobile-menu-opened::before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 500;
  }
  .c-mobile-menu-opened .c-menu {
    display: block !important;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    max-width: 100%;
    width: 600px;
    background-color: #fff;
    padding: 20px;
    z-index: 600;
    transition: all 0.3s ease-in-out;
    transform: translateX(-100%);
  }
  .c-mobile-menu-opened .c-menu .top-panel-links .top-panel-link {
    color: #333;
    font-weight: 400;
    font-size: 16px;
    border-bottom: 1px solid #eee;
  }
  .c-mobile-menu-opened .c-menu .top-panel-links .top-panel-link a {
    display: block;
    padding: 10px 10px;
    color: #333;
  }
  .c-mobile-menu-opened .c-menu .container {
    padding: 0;
    margin: 0;
  }
  .c-mobile-menu-opened .c-menu.c-menu-opened {
    transform: translateX(0);
  }
  .c-mobile-menu-opened .c-menu .c-menu__close_mobile {
    float: right;
  }
  .c-mobile-menu-opened .c-menu a {
    text-decoration: none;
  }
  .c-mobile-menu-opened .c-menu .c-menu__link {
    display: block;
  }
  .c-mobile-menu-opened .c-menu ul.c-menu__list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  .c-mobile-menu-opened .c-menu .c-menu__item {
    position: relative;
  }
  .c-mobile-menu-opened .c-menu .c-menu__item .open-ind {
    color: #666;
    pointer-events: none;
    position: absolute;
    top: 14px;
    right: 10px;
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
  }
  .c-mobile-menu-opened .c-menu .c-menu__item.c-menu__item-opened > a .open-ind {
    transform: rotate(270deg);
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 {
    display: none;
    margin-left: 20px;
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 .c-menu__item.is-level1 {
    border-bottom: 1px solid #ddd;
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 .c-menu__item.is-level1:last-child {
    border-bottom: 0;
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 .c-menu__item.is-level1.c-menu__item-opened {
    background-color: rgba(50, 50, 50, 0.02);
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 .c-menu__item.is-level1.c-menu__item-opened .c-menu__list.is-level2 {
    display: block;
  }
  .c-mobile-menu-opened .c-menu .c-menu__dropdown.is-level1 .c-menu__link.is-level-link1 {
    color: #333;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 10px;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level2 {
    display: none;
    padding-left: 10px;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level2 .c-menu__item.is-level2 {
    border-bottom: 1px solid #eee;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level2 .c-menu__item.is-level2:last-child {
    border-bottom: 0;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level2 .c-menu__link.is-level-link2 {
    padding: 10px 10px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0 {
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0.c-menu__item-opened {
    background-color: rgba(50, 50, 50, 0.02);
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0.c-menu__item-opened .c-menu__dropdown.is-level1 {
    display: block;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0.is-promo .c-menu__link.is-level-link0 {
    color: #b12121;
  }
  .c-mobile-menu-opened .c-menu .c-menu__list.is-level0 .c-menu__link.is-level-link0 {
    color: #333;
    font-weight: 700;
    font-size: 16px;
  }
  .c-mobile-menu-opened .c-menu__dropdown__popular_brands {
    border-top: 1px solid #ddd;
    padding: 10px;
  }
  .c-mobile-menu-opened .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__title {
    margin-bottom: 10px;
  }
  .c-mobile-menu-opened .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__list_item {
    margin-right: 10px;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    display: inline-flex;
    align-items: center;
    text-align: center;
    vertical-align: top;
  }
  .c-mobile-menu-opened .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__list_item img {
    max-width: 100%;
    max-height: 100%;
  }
}
@media (min-width: 992px) {
  .c-menu {
    position: static;
    border-top: 1px solid #eee;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    /**
    level 0
     */
  }
  .c-menu .is-show-all-link, .c-menu .top-panel-links, .c-menu .open-ind {
    display: none;
  }
  .c-menu a {
    text-decoration: none;
  }
  .c-menu ul.c-menu__list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
  }
  .c-menu .c-menu__list.is-level0 {
    display: flex;
    flex-direction: row;
    list-style: none;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0 {
    height: 100%;
    overflow: visible;
    flex: 0 0 auto;
    min-height: 0;
    min-width: 0;
    width: auto;
    padding: 0 20px;
    border-bottom: 0;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0:first-child {
    padding-left: 0;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0:last-child {
    padding-right: 0;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__item.is-level0.is-promo .c-menu__link.is-level-link0 {
    color: #b12121;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__link.is-level-link0 {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 0px 10px;
    width: auto;
    color: #333;
    font-weight: 700;
    font-size: 16px;
    line-height: 2em;
  }
  .c-menu .c-menu__list.is-level0 .c-menu__link.is-level-link0::after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    background-color: #b12121;
    top: calc(100% - 3px);
    left: 0px;
    transition: all 0.2s ease-in-out;
  }
  .c-menu .c-menu__dropdown.is-level1 {
    position: absolute;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    padding-bottom: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #eee;
  }
  .c-menu .c-menu__item.is-level0:hover .c-menu__link.is-level-link0::after {
    width: 100%;
  }
  .c-menu .c-menu__item.is-level0:hover .c-menu__dropdown.is-level1 {
    z-index: 50;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .c-menu .c-menu__item.is-level0:hover .c-menu__dropdown.is-level1:before {
    content: "";
    position: absolute;
    top: calc(100% - 10px);
    width: 100%;
    height: 20px;
    background-color: #fff;
    z-index: 2;
  }
  .c-menu .c-menu__item.is-level0:hover .c-menu__dropdown.is-level1::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    height: 5px;
    box-shadow: 0px 0px 8px 2px #000000;
    z-index: -1;
  }
  .c-menu .c-menu__list.is-level1 {
    margin-top: 20px;
  }
  .c-menu .c-menu__list.is-level1 .c-menu__item.is-level1 {
    width: 33.3333333%;
    float: left;
  }
  .c-menu .c-menu__list.is-level1 .c-menu__link.is-level-link1 {
    color: #222;
    font-size: 16px;
    font-weight: 600;
    line-height: 2em;
  }
  .c-menu .c-menu__list.is-level1 .c-menu__link.is-level-link1:hover {
    color: #b12121;
  }
  .c-menu .c-menu__list.is-level2 {
    margin-left: 12px;
  }
  .c-menu .c-menu__list.is-level2 .c-menu__link.is-level-link2 {
    color: #222;
    font-size: 14px;
    font-weight: 400;
    line-height: 2.1em;
  }
  .c-menu .c-menu__list.is-level2 .c-menu__link.is-level-link2:hover {
    color: #b12121;
  }

  .c-menu .c-menu__dropdown__popular_brands {
    margin-top: 20px;
  }
  .c-menu .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__title {
    font-size: 16px;
    font-weight: 600;
    color: #222;
    margin-bottom: 10px;
  }
  .c-menu .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__list_item {
    display: flex;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    align-items: center;
    justify-content: center;
    margin: 0 10px 10px 0;
    float: left;
  }
  .c-menu .c-menu__dropdown__popular_brands .c-menu__dropdown__popular_brands__list_item img {
    max-width: 100%;
    max-height: 100%;
  }
}
.comp-header-search {
  position: relative;
}
.comp-header-search .comp-header-search__wrapper {
  position: relative;
  overflow: visible;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete__loader {
  display: none;
}
.comp-header-search .comp-header-search__wrapper.is-autocomplete-loading .comp-header-search__autocomplete {
  min-height: 70px;
}
.comp-header-search .comp-header-search__wrapper.is-autocomplete-loading .comp-header-search__autocomplete__loader {
  display: block;
  z-index: 2;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 30px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.comp-header-search .comp-header-search__wrapper.is-autocomplete-loading .comp-header-search__autocomplete__loader svg {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete {
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  position: absolute;
  right: 0;
  width: 800px;
  max-width: calc(100vw - 160px);
  z-index: 2000;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_bottom_row {
  text-align: center;
  margin-top: 20px;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_bottom_row a {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  font-size: 14px;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_bottom_row a:hover {
  text-decoration: underline;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_list {
  border-top: 1px solid #eee;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_list a {
  font-size: 13px;
  color: #666;
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px 5px;
  font-weight: 500;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_list a:hover {
  color: #333333;
  text-decoration: underline;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_products {
  border-top: 1px solid #eee;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_products .comp-header-search__autocomplete_product {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_products .comp-header-search__autocomplete_product:hover, .comp-header-search .comp-header-search__wrapper .comp-header-search__autocomplete .comp-header-search__autocomplete_products .comp-header-search__autocomplete_product.is-selected {
  background-color: #fafafa;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form {
  position: relative;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form input.c-search__input {
  width: 300px;
  max-width: 100%;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
  padding: 0 40px 0 10px;
  border: 1px solid #f3f3f3;
  background-color: #f3f3f3;
  outline: 0;
  transition: all 0.2s ease-in-out;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form input.c-search__input:focus {
  border: 1px solid #eee;
  background-color: #fff;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form input.c-search__input:not(:placeholder-shown) + .c-comp-header-search__submit:hover svg {
  color: #b12121;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form input.c-search__input:not(:placeholder-shown) + .c-comp-header-search__submit svg {
  color: #333;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form .c-comp-header-search__submit {
  position: absolute;
  right: 6px;
  top: 3px;
}
.comp-header-search .comp-header-search__wrapper .comp-header-search__form .c-comp-header-search__submit svg {
  transition: all 0.2s ease-in-out;
  color: #999;
}

.comp-search-opened {
  overflow: hidden;
}
.comp-search-opened::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 500;
}
.comp-search-opened .comp-header-search__wrapper {
  padding: 10px;
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 550;
  display: flex !important;
  align-items: center;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
  flex-wrap: wrap;
}
.comp-search-opened .comp-header-search__wrapper.c-menu-opened {
  opacity: 1;
  transform: none;
}
.comp-search-opened .comp-header-search__wrapper .comp-header-search__autocomplete {
  position: static;
  width: 100%;
  max-width: none;
  margin-top: 20px;
  max-height: calc(100vh - 140px);
  overflow: auto;
}
.comp-search-opened .comp-header-search__wrapper .comp-header-search__form {
  flex-grow: 1;
  margin-right: 20px;
}
.comp-search-opened .comp-header-search__wrapper .comp-header-search__form input.c-search__input {
  width: 100%;
}

.comp-header-cart .comp-header-cart__counter {
  position: absolute;
  font-size: 10px;
  background-color: #b12121;
  color: #fff;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  border-radius: 50%;
  line-height: 17px;
  text-align: center;
  border: 2px solid #fff;
}

.c-cart-dropdown {
  padding: 10px;
}
.c-cart-dropdown .c-cart-dropdown__item {
  padding: 10px;
  margin-bottom: 0;
  transition: all 0.2s ease-in-out;
}
.c-cart-dropdown .c-cart-dropdown__item:hover {
  background-color: #fafafa;
}
.c-cart-dropdown .c-cart-dropdown__item + .c-cart-dropdown__item {
  border-top: 1px solid #eee;
}
.c-cart-dropdown .c-cart-dropdown__bottom {
  border-top: 1px solid #eee;
  padding-top: 10px;
  text-align: right;
}

.comp-reldrop {
  position: relative;
}
.comp-reldrop .c-reldrop__dropdown {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  right: 0;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  z-index: 600;
}

@media (min-width: 992px) {
  .comp-reldrop:hover .c-reldrop__dropdown {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
  }
}
.c-dropbutton {
  display: block;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-top: -2px;
}
.c-dropbutton a {
  text-decoration: none;
}
.c-dropbutton.is-dropped {
  z-index: 20;
}
.c-dropbutton.is-dropped .c-dropbutton__dropdown {
  visibility: visible;
  pointer-events: all;
  background-color: #fff;
  height: auto;
  border-bottom: 1px solid #aaa;
}
.c-dropbutton.is-dropped.is-dropped .c-dropbutton__label {
  box-shadow: inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #fff;
}
.c-dropbutton.is-dropped .c-dropbutton__label svg {
  transform: rotate(270deg);
}
.c-dropbutton .c-dropbutton__label {
  cursor: pointer;
  padding: 10px 10px 12px 10px;
  display: block;
  background-color: #fff;
  position: relative;
  box-shadow: inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #aaa;
  z-index: 6;
}
.c-dropbutton .c-dropbutton__label svg {
  color: #999;
  transition: all 0.2s ease-in-out;
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
  vertical-align: -2px;
  margin-left: 10px;
  top: 14px;
  position: absolute;
  right: 10px;
}
.c-dropbutton .c-dropbutton__label .c-dropbutton__label__active {
  display: inline-block;
  background-color: #ddd;
  padding: 0px 5px;
}
.c-dropbutton.is-active .c-dropbutton__dropdown {
  border-bottom: 1px solid #666;
}
.c-dropbutton .c-dropbutton__dropdown {
  position: relative;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  margin-top: -1px;
  z-index: 5;
  height: 0;
  overflow: hidden;
}
.c-dropbutton .c-dropbutton__dropdown.is-list a {
  color: #333;
  display: block;
  padding: 8px;
  border-bottom: 1px solid #eee;
}
.c-dropbutton .c-dropbutton__dropdown.is-list a.is-selected {
  font-weight: bold;
}
.c-dropbutton .c-dropbutton__dropdown.is-list a:last-child {
  border-bottom: 0;
}
.c-dropbutton .c-dropbutton__dropdown.is-list a:hover {
  background-color: #fafafa;
  color: #b12121;
}
.c-dropbutton .c-dropbutton__dropdown__range_row {
  display: flex;
  align-items: center;
  margin: 20px 10px;
  position: relative;
}
.c-dropbutton .c-dropbutton__dropdown__range_row .c-dropbutton__dropdown__range_separator {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25px;
  border-bottom: 1px solid #ddd;
  height: 1px;
  margin: 0 10px;
}
.c-dropbutton .c-dropbutton__dropdown__range_row .c-dropbutton__dropdown__input {
  flex-basis: 50%;
}
.c-dropbutton .c-dropbutton__dropdown__input {
  position: relative;
}
.c-dropbutton .c-dropbutton__dropdown__input input {
  border: 1px solid #ddd;
  height: 30px;
  line-height: 15px;
  font-size: 14px;
  width: 100%;
  /* padding-left: 10px; */
  display: block;
  outline: 0;
  padding-left: 7px;
  padding-right: 40px;
}
.c-dropbutton .c-dropbutton__dropdown__input input:focus {
  border: 1px solid #666;
}
.c-dropbutton .c-dropbutton__dropdown__input .c-dropbutton__dropdown__input_unit {
  line-height: 32px;
  position: absolute;
  right: 10px;
  top: 0;
}
.c-dropbutton .c-dropbutton__dropdown__checklist {
  max-height: 229px;
  overflow: auto;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label {
  color: #333;
  display: block;
  padding: 8px;
  border-bottom: 1px solid #eee;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label > span .icon {
  border: 1px solid #999;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  /* padding: 1px; */
  vertical-align: -3px;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label > span svg {
  width: 16px;
  height: 16px;
  opacity: 0;
  color: #444;
  display: block;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label input {
  display: none;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label input:checked + span {
  font-weight: bold;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label input:checked + span .icon {
  border: 1px solid #333;
}
.c-dropbutton .c-dropbutton__dropdown__checklist label input:checked + span svg {
  opacity: 1;
}
.c-dropbutton .c-dropbutton__dropdown__tools {
  display: flex;
  padding: 10px;
}
.c-dropbutton .c-dropbutton__dropdown__tools .c-dropbutton__dropdown__tools__clear {
  cursor: pointer;
  padding: 10px;
  font-size: 13px;
  flex-shrink: 1;
  flex-grow: 0;
  text-align: center;
  background-color: #eee;
  margin-right: 10px;
}
.c-dropbutton .c-dropbutton__dropdown__tools .c-dropbutton__dropdown__tools__clear:hover {
  background-color: #ddd;
}
.c-dropbutton .c-dropbutton__dropdown__tools .c-dropbutton__dropdown__tools__save {
  cursor: pointer;
  padding: 10px;
  font-size: 13px;
  flex-shrink: 1;
  flex-grow: 1;
  text-align: center;
  background-color: #b12121;
  color: #fff;
  font-weight: bold;
}
.c-dropbutton .c-dropbutton__dropdown__tools .c-dropbutton__dropdown__tools__save:hover {
  background-color: #760c0c;
}

@media (min-width: 768px) {
  .c-dropbutton {
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .c-dropbutton .c-dropbutton__label {
    padding: 10px;
    display: inline-block;
    box-shadow: inset -1px 0 0 0 #aaa, inset 1px 0 0 0 #aaa, inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #aaa;
  }
  .c-dropbutton .c-dropbutton__label svg {
    position: static;
  }
  .c-dropbutton .c-dropbutton__dropdown {
    width: 0;
    position: absolute;
    border: 1px solid #ccc;
    display: block;
  }
  .c-dropbutton.is-right .c-dropbutton__dropdown {
    right: 0;
  }
  .c-dropbutton.is-dropped .c-dropbutton__dropdown {
    width: 300px;
  }
  .c-dropbutton.is-dropped .c-dropbutton__label {
    box-shadow: inset -1px 0 0 0 #ccc, inset 1px 0 0 0 #ccc, inset 0 1px 0 0 #ccc, inset 0 -1px 0 0 #fff !important;
  }
  .c-dropbutton.is-active .c-dropbutton__label {
    box-shadow: inset -1px 0 0 0 #999, inset 1px 0 0 0 #999, inset 0 1px 0 0 #999, inset 0 -1px 0 0 #999;
  }
  .c-dropbutton.is-active.is-dropped .c-dropbutton__label {
    box-shadow: inset -1px 0 0 0 #999, inset 1px 0 0 0 #999, inset 0 1px 0 0 #999, inset 0 -1px 0 0 #fff;
  }
}
.c-range-select {
  margin: 20px 10px;
  height: 20px;
  position: relative;
  user-select: none;
}
.c-range-select::before {
  content: "";
  background: linear-gradient(0deg, white 0%, white 45%, #cdcdcd 45%, #cdcdcd 55%, white 55%, white 100%);
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
  height: 20px;
}
.c-range-select .c-range-select__selected {
  content: "";
  background: linear-gradient(0deg, white 0%, white 45%, #b71a1a 45%, #b71a1a 55%, white 55%, white 100%);
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
  height: 20px;
}
.c-range-select .c-range-select__range::before {
  content: "";
  background: linear-gradient(0deg, white 0%, white 45%, #b71a1a 45%, #b71a1a 55%, white 55%, white 100%);
  display: block;
  position: absolute;
  left: 10px;
  right: 10px;
  height: 20px;
}
.c-range-select .c-range-select__range {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  height: 20px;
}
.c-range-select .c-range-select__range_from,
.c-range-select .c-range-select__range_to {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #bc1c1c;
  border-radius: 50%;
}

body.is-gallery-active {
  overflow: hidden;
}

.c-product-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.c-product-gallery .c-product-gallery__close {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.c-product-gallery .c-product-gallery__close svg {
  height: 32px;
  width: 32px;
  color: #000;
  pointer-events: none;
}
.c-product-gallery .c-product-gallery__image {
  background-color: #fff;
  flex-grow: 1;
  overflow: hidden;
}
.c-product-gallery .c-product-gallery__image .c-product-gallery__image_bg {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.2s ease-in-out;
  cursor: zoom-in;
}
.c-product-gallery .c-product-gallery__image .c-product-gallery__image_bg.is-zoom {
  width: 300%;
  height: 300%;
  cursor: zoom-out;
}
.c-product-gallery .c-product-gallery__image .c-product-gallery__image_bg.is-non-transition {
  transition: none !important;
}
.c-product-gallery .c-product-gallery__thumbs {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-product-gallery .c-product-gallery__thumbs .c-product-gallery__thumbs__item {
  display: block;
  height: 90px;
  width: 90px;
  border: 1px solid #eee;
  text-align: center;
  padding: 5px;
  margin: 10px 10px;
}
.c-product-gallery .c-product-gallery__thumbs .c-product-gallery__thumbs__item.is-selected {
  border: 1px solid #333;
}

.c-tabs {
  margin-top: 20px;
  border-bottom: 1px solid #eee;
}
.c-tabs .c-tabs__list {
  display: none;
}
.c-tabs .c-tabs__content .c-tabs__content_acc {
  position: relative;
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
}
.c-tabs .c-tabs__content .c-tabs__content_acc span {
  display: block;
  pointer-events: none;
}
.c-tabs .c-tabs__content .c-tabs__content_acc svg {
  position: absolute;
  right: 10px;
  height: 16px;
  width: 16px;
  top: 12px;
  transform: rotate(90deg);
  color: #333;
  pointer-events: none;
}
.c-tabs .c-tabs__content .c-tabs__content_acc.is-opened svg {
  transform: rotate(270deg);
}
.c-tabs .c-tabs__content .c-tabs__content_item {
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  max-height: 0px;
  overflow: hidden;
}
.c-tabs .c-tabs__content .c-tabs__content_item .c-tabs__content_item_wrapper {
  padding: 10px 10px;
}

@media (min-width: 768px) {
  .c-tabs .c-tabs__list {
    display: block;
    border-bottom: 1px solid #eee;
  }
  .c-tabs .c-tabs__list .c-tabs__list_item {
    display: inline-block;
    padding: 8px 14px;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .c-tabs .c-tabs__list .c-tabs__list_item.is-selected {
    border-bottom: 2px solid #760c0c;
    font-weight: bold;
  }
  .c-tabs .c-tabs__list .c-tabs__list_item:hover {
    border-bottom: 2px solid #b12121;
  }
  .c-tabs .c-tabs__content .c-tabs__content_acc {
    display: none;
  }
  .c-tabs .c-tabs__content .c-tabs__content_item {
    font-size: 14px;
    display: none;
    margin-top: 20px;
    max-height: none;
    overflow: auto;
  }
  .c-tabs .c-tabs__content .c-tabs__content_item.is-selected {
    display: block;
  }
}
.c-details {
  margin-top: 20px;
}
.c-details .c-details__header {
  padding: 10px;
  border-top: 1px solid #eee;
  font-size: 16px;
  font-weight: 600;
}
.c-details .c-details__param {
  font-size: 16px;
  line-height: 1.6em;
  margin: 10px 10px;
}
.c-details .c-details__param .c-details__param_name {
  display: block;
  font-size: 12px;
  color: #666;
}

@media (min-width: 768px) {
  .c-details .c-details__header {
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding: 8px 14px 10px 14px;
    font-size: 14px;
    cursor: pointer;
  }
  .c-details .c-details__param {
    font-size: 14px;
    line-height: 1.6em;
    margin: 8px 14px;
  }
  .c-details .c-details__param .c-details__param_name {
    display: block;
    font-size: 12px;
    color: #666;
  }
}
.c-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
.c-modal .c-modal-name {
  font-weight: bold;
  margin-bottom: 10px;
}
.c-modal .c-modal-name .c-modal-name__close {
  float: right;
  cursor: pointer;
}
.c-modal .c-modal-name .c-modal-name__close:hover svg {
  color: #333;
}
.c-modal .c-modal-name .c-modal-name__close svg {
  width: 24px;
  height: 24px;
  color: #aaa;
  pointer-events: none;
}
.c-modal .c-modal-content {
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
.c-modal .c-modal-buttons {
  margin-top: 10px;
}
.c-modal .c-modal-buttons .c-button {
  margin: 5px;
}
.c-modal.is-narrow .c-modal-content {
  width: 500px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .c-modal {
    padding-top: 2%;
  }
  .c-modal .c-modal-content {
    width: 75%;
  }
}
@media (min-width: 992px) {
  .c-modal {
    padding-top: 5%;
  }
  .c-modal .c-modal-content {
    width: 50%;
  }
}
.c-border-block {
  padding: 10px;
  border: 1px solid #b7b7b7;
}
.c-border-block.is-selected {
  border: 1px solid #888;
  outline: 1px solid #888;
}
.c-border-block.is-selectable {
  cursor: pointer;
}
.c-border-block.is-selectable:hover {
  border: 1px solid #b12121;
}

.c-modal-gmap .c-modal-gmap__toolbar {
  margin-bottom: 10px;
}
.c-modal-gmap .c-modal-gmap__toolbar .c-modal-gmap__toolbar__search_input {
  border: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  width: 100%;
  padding-left: 10px;
  display: block;
  outline: 0;
}
.c-modal-gmap .c-modal-gmap__map-container {
  height: calc(100vh - 120px);
  max-height: 600px;
  width: 100%;
  position: relative;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point {
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.4);
  padding: 10px;
  background-color: #fff;
  z-index: 2;
  min-width: 300px;
  max-width: 40%;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__close {
  float: right;
  cursor: pointer;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__close:hover svg {
  color: #333;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__close svg {
  width: 24px;
  height: 24px;
  color: #aaa;
  pointer-events: none;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__name {
  color: #333;
  font-size: 18px;
  font-weight: 600;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__type {
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__address {
  margin-top: 10px;
  color: #333;
  font-size: 13px;
  font-weight: 400;
  max-width: 300px;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__hours_title {
  font-size: 13px;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__hours {
  font-size: 13px;
  font-weight: 400;
  color: #333;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__hours b {
  font-weight: 500;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point .c-modal-gmap__map-container__point__select {
  margin-top: 20px;
}
.c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__map {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.c-modal-gmap.loading .c-modal-gmap__map-container::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 320px) {
  .c-modal-gmap .c-modal-gmap__map-container .c-modal-gmap__map-container__point {
    top: 0;
    left: 0;
    max-width: 100%;
    min-width: auto;
  }
}
.c-submenu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  max-width: 100%;
  width: 600px;
  background-color: #fff;
  padding: 20px;
  z-index: 600;
  transition: all 0.3s ease-in-out;
  transform: translateX(-100%);
}
.c-submenu .c-submenu__list {
  list-style: none;
  padding-left: 0;
  padding-bottom: 0;
  border-top: 1px solid #eee;
}
.c-submenu .c-submenu__list .c-submenu__item_link {
  display: block;
  font-size: 16px;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  border-bottom: 1px solid #eee;
}
.c-submenu .c-submenu__list .c-submenu__item_link.is-active {
  font-weight: 700;
}
.c-submenu .c-submenu__list .c-submenu__item_link:hover {
  background-color: #fafafa;
}
.c-submenu .c-submenu__pre_mobile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.c-submenu .c-submenu__pre_mobile .c-submenu__title {
  flex-grow: 1;
}
.c-submenu .c-submenu__pre_mobile .svgbutton {
  flex-grow: 0;
  justify-self: end;
}

.sub-menu-open {
  overflow: hidden;
}
.sub-menu-open::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 500;
}
.sub-menu-open .c-submenu.c-menu-opened {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .c-submenu {
    display: block !important;
    position: static !important;
    transform: none !important;
    padding: 0;
  }
  .c-submenu .c-submenu__list .c-submenu__item_link {
    font-size: 14px;
  }
}
.app-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.app-form .loader {
  display: none;
}
.app-form.is-loading {
  position: relative;
}
.app-form.is-loading .loader {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.app-form.is-loading .loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  color: #222;
  font-size: 6px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0);
  display: block;
}
.app-form ul {
  color: #b80000;
  list-style: none;
  font-weight: 600;
  margin-left: 0;
  font-size: 13px;
  margin-bottom: 30px;
  border: 1px solid #b80000;
  padding: 10px;
}
.app-form .form-row-submit {
  flex-basis: 100%;
  text-align: center;
}
.app-form .form-row-submit a {
  color: #333;
  margin: 20px 0 10px 0;
  text-align: center;
  display: inline-block;
}
.app-form .form-row-submit a:hover {
  text-decoration: underline;
}
.app-form .checkbox-form-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.app-form .checkbox-form-row label {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.app-form .form-row {
  flex-basis: 100%;
  margin-bottom: 20px;
}
.app-form .form-row.w-1-proc {
  flex-basis: 1%;
}
.app-form .form-row.w-2-proc {
  flex-basis: 2%;
}
.app-form .form-row.w-3-proc {
  flex-basis: 3%;
}
.app-form .form-row.w-4-proc {
  flex-basis: 4%;
}
.app-form .form-row.w-5-proc {
  flex-basis: 5%;
}
.app-form .form-row.w-6-proc {
  flex-basis: 6%;
}
.app-form .form-row.w-7-proc {
  flex-basis: 7%;
}
.app-form .form-row.w-8-proc {
  flex-basis: 8%;
}
.app-form .form-row.w-9-proc {
  flex-basis: 9%;
}
.app-form .form-row.w-10-proc {
  flex-basis: 10%;
}
.app-form .form-row.w-11-proc {
  flex-basis: 11%;
}
.app-form .form-row.w-12-proc {
  flex-basis: 12%;
}
.app-form .form-row.w-13-proc {
  flex-basis: 13%;
}
.app-form .form-row.w-14-proc {
  flex-basis: 14%;
}
.app-form .form-row.w-15-proc {
  flex-basis: 15%;
}
.app-form .form-row.w-16-proc {
  flex-basis: 16%;
}
.app-form .form-row.w-17-proc {
  flex-basis: 17%;
}
.app-form .form-row.w-18-proc {
  flex-basis: 18%;
}
.app-form .form-row.w-19-proc {
  flex-basis: 19%;
}
.app-form .form-row.w-20-proc {
  flex-basis: 20%;
}
.app-form .form-row.w-21-proc {
  flex-basis: 21%;
}
.app-form .form-row.w-22-proc {
  flex-basis: 22%;
}
.app-form .form-row.w-23-proc {
  flex-basis: 23%;
}
.app-form .form-row.w-24-proc {
  flex-basis: 24%;
}
.app-form .form-row.w-25-proc {
  flex-basis: 25%;
}
.app-form .form-row.w-26-proc {
  flex-basis: 26%;
}
.app-form .form-row.w-27-proc {
  flex-basis: 27%;
}
.app-form .form-row.w-28-proc {
  flex-basis: 28%;
}
.app-form .form-row.w-29-proc {
  flex-basis: 29%;
}
.app-form .form-row.w-30-proc {
  flex-basis: 30%;
}
.app-form .form-row.w-31-proc {
  flex-basis: 31%;
}
.app-form .form-row.w-32-proc {
  flex-basis: 32%;
}
.app-form .form-row.w-33-proc {
  flex-basis: 33%;
}
.app-form .form-row.w-34-proc {
  flex-basis: 34%;
}
.app-form .form-row.w-35-proc {
  flex-basis: 35%;
}
.app-form .form-row.w-36-proc {
  flex-basis: 36%;
}
.app-form .form-row.w-37-proc {
  flex-basis: 37%;
}
.app-form .form-row.w-38-proc {
  flex-basis: 38%;
}
.app-form .form-row.w-39-proc {
  flex-basis: 39%;
}
.app-form .form-row.w-40-proc {
  flex-basis: 40%;
}
.app-form .form-row.w-41-proc {
  flex-basis: 41%;
}
.app-form .form-row.w-42-proc {
  flex-basis: 42%;
}
.app-form .form-row.w-43-proc {
  flex-basis: 43%;
}
.app-form .form-row.w-44-proc {
  flex-basis: 44%;
}
.app-form .form-row.w-45-proc {
  flex-basis: 45%;
}
.app-form .form-row.w-46-proc {
  flex-basis: 46%;
}
.app-form .form-row.w-47-proc {
  flex-basis: 47%;
}
.app-form .form-row.w-48-proc {
  flex-basis: 48%;
}
.app-form .form-row.w-49-proc {
  flex-basis: 49%;
}
.app-form .form-row.w-50-proc {
  flex-basis: 50%;
}
.app-form .form-row.w-51-proc {
  flex-basis: 51%;
}
.app-form .form-row.w-52-proc {
  flex-basis: 52%;
}
.app-form .form-row.w-53-proc {
  flex-basis: 53%;
}
.app-form .form-row.w-54-proc {
  flex-basis: 54%;
}
.app-form .form-row.w-55-proc {
  flex-basis: 55%;
}
.app-form .form-row.w-56-proc {
  flex-basis: 56%;
}
.app-form .form-row.w-57-proc {
  flex-basis: 57%;
}
.app-form .form-row.w-58-proc {
  flex-basis: 58%;
}
.app-form .form-row.w-59-proc {
  flex-basis: 59%;
}
.app-form .form-row.w-60-proc {
  flex-basis: 60%;
}
.app-form .form-row.w-61-proc {
  flex-basis: 61%;
}
.app-form .form-row.w-62-proc {
  flex-basis: 62%;
}
.app-form .form-row.w-63-proc {
  flex-basis: 63%;
}
.app-form .form-row.w-64-proc {
  flex-basis: 64%;
}
.app-form .form-row.w-65-proc {
  flex-basis: 65%;
}
.app-form .form-row.w-66-proc {
  flex-basis: 66%;
}
.app-form .form-row.w-67-proc {
  flex-basis: 67%;
}
.app-form .form-row.w-68-proc {
  flex-basis: 68%;
}
.app-form .form-row.w-69-proc {
  flex-basis: 69%;
}
.app-form .form-row.w-70-proc {
  flex-basis: 70%;
}
.app-form .form-row.w-71-proc {
  flex-basis: 71%;
}
.app-form .form-row.w-72-proc {
  flex-basis: 72%;
}
.app-form .form-row.w-73-proc {
  flex-basis: 73%;
}
.app-form .form-row.w-74-proc {
  flex-basis: 74%;
}
.app-form .form-row.w-75-proc {
  flex-basis: 75%;
}
.app-form .form-row.w-76-proc {
  flex-basis: 76%;
}
.app-form .form-row.w-77-proc {
  flex-basis: 77%;
}
.app-form .form-row.w-78-proc {
  flex-basis: 78%;
}
.app-form .form-row.w-79-proc {
  flex-basis: 79%;
}
.app-form .form-row.w-80-proc {
  flex-basis: 80%;
}
.app-form .form-row.w-81-proc {
  flex-basis: 81%;
}
.app-form .form-row.w-82-proc {
  flex-basis: 82%;
}
.app-form .form-row.w-83-proc {
  flex-basis: 83%;
}
.app-form .form-row.w-84-proc {
  flex-basis: 84%;
}
.app-form .form-row.w-85-proc {
  flex-basis: 85%;
}
.app-form .form-row.w-86-proc {
  flex-basis: 86%;
}
.app-form .form-row.w-87-proc {
  flex-basis: 87%;
}
.app-form .form-row.w-88-proc {
  flex-basis: 88%;
}
.app-form .form-row.w-89-proc {
  flex-basis: 89%;
}
.app-form .form-row.w-90-proc {
  flex-basis: 90%;
}
.app-form .form-row.w-91-proc {
  flex-basis: 91%;
}
.app-form .form-row.w-92-proc {
  flex-basis: 92%;
}
.app-form .form-row.w-93-proc {
  flex-basis: 93%;
}
.app-form .form-row.w-94-proc {
  flex-basis: 94%;
}
.app-form .form-row.w-95-proc {
  flex-basis: 95%;
}
.app-form .form-row.w-96-proc {
  flex-basis: 96%;
}
.app-form .form-row.w-97-proc {
  flex-basis: 97%;
}
.app-form .form-row.w-98-proc {
  flex-basis: 98%;
}
.app-form .form-row.w-99-proc {
  flex-basis: 99%;
}
.app-form .form-row.w-100-proc {
  flex-basis: 100%;
}
.app-form .form-row label {
  display: block;
  font-weight: 500;
  color: #333;
  font-size: 13px;
  margin-bottom: 5px;
}
.app-form .form-row textarea {
  height: 80px !important;
}
.app-form .form-row input[type=text], .app-form .form-row input[type=password], .app-form .form-row input[type=email], .app-form .form-row select, .app-form .form-row textarea {
  border: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  width: 100%;
  padding-left: 10px;
  display: block;
  outline: 0;
  color: #000;
}
.app-form .form-row input[type=text]:focus, .app-form .form-row input[type=password]:focus, .app-form .form-row input[type=email]:focus, .app-form .form-row select:focus, .app-form .form-row textarea:focus {
  border: 1px solid #666;
}
.app-form .form-row input[type=text]:disabled, .app-form .form-row input[type=password]:disabled, .app-form .form-row input[type=email]:disabled, .app-form .form-row select:disabled, .app-form .form-row textarea:disabled {
  border: 1px solid #ddd;
  background-color: #e5e5e5;
  color: #777;
}
.app-form .form-row .help-text {
  color: #333;
  font-size: 12px;
  padding: 5px 0;
}
.app-form .form-row ul {
  margin-bottom: 0px;
  border: 0;
  padding: 0;
}
.app-form .form-radio-row label {
  font-size: 15px;
  color: #000;
  display: inline-block;
  margin-right: 20px;
  margin-left: 5px;
}

@media (min-width: 576px) {
  .form-row.w-sm-1-proc {
    flex-basis: 1%;
  }
  .form-row.w-sm-2-proc {
    flex-basis: 2%;
  }
  .form-row.w-sm-3-proc {
    flex-basis: 3%;
  }
  .form-row.w-sm-4-proc {
    flex-basis: 4%;
  }
  .form-row.w-sm-5-proc {
    flex-basis: 5%;
  }
  .form-row.w-sm-6-proc {
    flex-basis: 6%;
  }
  .form-row.w-sm-7-proc {
    flex-basis: 7%;
  }
  .form-row.w-sm-8-proc {
    flex-basis: 8%;
  }
  .form-row.w-sm-9-proc {
    flex-basis: 9%;
  }
  .form-row.w-sm-10-proc {
    flex-basis: 10%;
  }
  .form-row.w-sm-11-proc {
    flex-basis: 11%;
  }
  .form-row.w-sm-12-proc {
    flex-basis: 12%;
  }
  .form-row.w-sm-13-proc {
    flex-basis: 13%;
  }
  .form-row.w-sm-14-proc {
    flex-basis: 14%;
  }
  .form-row.w-sm-15-proc {
    flex-basis: 15%;
  }
  .form-row.w-sm-16-proc {
    flex-basis: 16%;
  }
  .form-row.w-sm-17-proc {
    flex-basis: 17%;
  }
  .form-row.w-sm-18-proc {
    flex-basis: 18%;
  }
  .form-row.w-sm-19-proc {
    flex-basis: 19%;
  }
  .form-row.w-sm-20-proc {
    flex-basis: 20%;
  }
  .form-row.w-sm-21-proc {
    flex-basis: 21%;
  }
  .form-row.w-sm-22-proc {
    flex-basis: 22%;
  }
  .form-row.w-sm-23-proc {
    flex-basis: 23%;
  }
  .form-row.w-sm-24-proc {
    flex-basis: 24%;
  }
  .form-row.w-sm-25-proc {
    flex-basis: 25%;
  }
  .form-row.w-sm-26-proc {
    flex-basis: 26%;
  }
  .form-row.w-sm-27-proc {
    flex-basis: 27%;
  }
  .form-row.w-sm-28-proc {
    flex-basis: 28%;
  }
  .form-row.w-sm-29-proc {
    flex-basis: 29%;
  }
  .form-row.w-sm-30-proc {
    flex-basis: 30%;
  }
  .form-row.w-sm-31-proc {
    flex-basis: 31%;
  }
  .form-row.w-sm-32-proc {
    flex-basis: 32%;
  }
  .form-row.w-sm-33-proc {
    flex-basis: 33%;
  }
  .form-row.w-sm-34-proc {
    flex-basis: 34%;
  }
  .form-row.w-sm-35-proc {
    flex-basis: 35%;
  }
  .form-row.w-sm-36-proc {
    flex-basis: 36%;
  }
  .form-row.w-sm-37-proc {
    flex-basis: 37%;
  }
  .form-row.w-sm-38-proc {
    flex-basis: 38%;
  }
  .form-row.w-sm-39-proc {
    flex-basis: 39%;
  }
  .form-row.w-sm-40-proc {
    flex-basis: 40%;
  }
  .form-row.w-sm-41-proc {
    flex-basis: 41%;
  }
  .form-row.w-sm-42-proc {
    flex-basis: 42%;
  }
  .form-row.w-sm-43-proc {
    flex-basis: 43%;
  }
  .form-row.w-sm-44-proc {
    flex-basis: 44%;
  }
  .form-row.w-sm-45-proc {
    flex-basis: 45%;
  }
  .form-row.w-sm-46-proc {
    flex-basis: 46%;
  }
  .form-row.w-sm-47-proc {
    flex-basis: 47%;
  }
  .form-row.w-sm-48-proc {
    flex-basis: 48%;
  }
  .form-row.w-sm-49-proc {
    flex-basis: 49%;
  }
  .form-row.w-sm-50-proc {
    flex-basis: 50%;
  }
  .form-row.w-sm-51-proc {
    flex-basis: 51%;
  }
  .form-row.w-sm-52-proc {
    flex-basis: 52%;
  }
  .form-row.w-sm-53-proc {
    flex-basis: 53%;
  }
  .form-row.w-sm-54-proc {
    flex-basis: 54%;
  }
  .form-row.w-sm-55-proc {
    flex-basis: 55%;
  }
  .form-row.w-sm-56-proc {
    flex-basis: 56%;
  }
  .form-row.w-sm-57-proc {
    flex-basis: 57%;
  }
  .form-row.w-sm-58-proc {
    flex-basis: 58%;
  }
  .form-row.w-sm-59-proc {
    flex-basis: 59%;
  }
  .form-row.w-sm-60-proc {
    flex-basis: 60%;
  }
  .form-row.w-sm-61-proc {
    flex-basis: 61%;
  }
  .form-row.w-sm-62-proc {
    flex-basis: 62%;
  }
  .form-row.w-sm-63-proc {
    flex-basis: 63%;
  }
  .form-row.w-sm-64-proc {
    flex-basis: 64%;
  }
  .form-row.w-sm-65-proc {
    flex-basis: 65%;
  }
  .form-row.w-sm-66-proc {
    flex-basis: 66%;
  }
  .form-row.w-sm-67-proc {
    flex-basis: 67%;
  }
  .form-row.w-sm-68-proc {
    flex-basis: 68%;
  }
  .form-row.w-sm-69-proc {
    flex-basis: 69%;
  }
  .form-row.w-sm-70-proc {
    flex-basis: 70%;
  }
  .form-row.w-sm-71-proc {
    flex-basis: 71%;
  }
  .form-row.w-sm-72-proc {
    flex-basis: 72%;
  }
  .form-row.w-sm-73-proc {
    flex-basis: 73%;
  }
  .form-row.w-sm-74-proc {
    flex-basis: 74%;
  }
  .form-row.w-sm-75-proc {
    flex-basis: 75%;
  }
  .form-row.w-sm-76-proc {
    flex-basis: 76%;
  }
  .form-row.w-sm-77-proc {
    flex-basis: 77%;
  }
  .form-row.w-sm-78-proc {
    flex-basis: 78%;
  }
  .form-row.w-sm-79-proc {
    flex-basis: 79%;
  }
  .form-row.w-sm-80-proc {
    flex-basis: 80%;
  }
  .form-row.w-sm-81-proc {
    flex-basis: 81%;
  }
  .form-row.w-sm-82-proc {
    flex-basis: 82%;
  }
  .form-row.w-sm-83-proc {
    flex-basis: 83%;
  }
  .form-row.w-sm-84-proc {
    flex-basis: 84%;
  }
  .form-row.w-sm-85-proc {
    flex-basis: 85%;
  }
  .form-row.w-sm-86-proc {
    flex-basis: 86%;
  }
  .form-row.w-sm-87-proc {
    flex-basis: 87%;
  }
  .form-row.w-sm-88-proc {
    flex-basis: 88%;
  }
  .form-row.w-sm-89-proc {
    flex-basis: 89%;
  }
  .form-row.w-sm-90-proc {
    flex-basis: 90%;
  }
  .form-row.w-sm-91-proc {
    flex-basis: 91%;
  }
  .form-row.w-sm-92-proc {
    flex-basis: 92%;
  }
  .form-row.w-sm-93-proc {
    flex-basis: 93%;
  }
  .form-row.w-sm-94-proc {
    flex-basis: 94%;
  }
  .form-row.w-sm-95-proc {
    flex-basis: 95%;
  }
  .form-row.w-sm-96-proc {
    flex-basis: 96%;
  }
  .form-row.w-sm-97-proc {
    flex-basis: 97%;
  }
  .form-row.w-sm-98-proc {
    flex-basis: 98%;
  }
  .form-row.w-sm-99-proc {
    flex-basis: 99%;
  }
  .form-row.w-sm-100-proc {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .form-row.w-md-1-proc {
    flex-basis: 1%;
  }
  .form-row.w-md-2-proc {
    flex-basis: 2%;
  }
  .form-row.w-md-3-proc {
    flex-basis: 3%;
  }
  .form-row.w-md-4-proc {
    flex-basis: 4%;
  }
  .form-row.w-md-5-proc {
    flex-basis: 5%;
  }
  .form-row.w-md-6-proc {
    flex-basis: 6%;
  }
  .form-row.w-md-7-proc {
    flex-basis: 7%;
  }
  .form-row.w-md-8-proc {
    flex-basis: 8%;
  }
  .form-row.w-md-9-proc {
    flex-basis: 9%;
  }
  .form-row.w-md-10-proc {
    flex-basis: 10%;
  }
  .form-row.w-md-11-proc {
    flex-basis: 11%;
  }
  .form-row.w-md-12-proc {
    flex-basis: 12%;
  }
  .form-row.w-md-13-proc {
    flex-basis: 13%;
  }
  .form-row.w-md-14-proc {
    flex-basis: 14%;
  }
  .form-row.w-md-15-proc {
    flex-basis: 15%;
  }
  .form-row.w-md-16-proc {
    flex-basis: 16%;
  }
  .form-row.w-md-17-proc {
    flex-basis: 17%;
  }
  .form-row.w-md-18-proc {
    flex-basis: 18%;
  }
  .form-row.w-md-19-proc {
    flex-basis: 19%;
  }
  .form-row.w-md-20-proc {
    flex-basis: 20%;
  }
  .form-row.w-md-21-proc {
    flex-basis: 21%;
  }
  .form-row.w-md-22-proc {
    flex-basis: 22%;
  }
  .form-row.w-md-23-proc {
    flex-basis: 23%;
  }
  .form-row.w-md-24-proc {
    flex-basis: 24%;
  }
  .form-row.w-md-25-proc {
    flex-basis: 25%;
  }
  .form-row.w-md-26-proc {
    flex-basis: 26%;
  }
  .form-row.w-md-27-proc {
    flex-basis: 27%;
  }
  .form-row.w-md-28-proc {
    flex-basis: 28%;
  }
  .form-row.w-md-29-proc {
    flex-basis: 29%;
  }
  .form-row.w-md-30-proc {
    flex-basis: 30%;
  }
  .form-row.w-md-31-proc {
    flex-basis: 31%;
  }
  .form-row.w-md-32-proc {
    flex-basis: 32%;
  }
  .form-row.w-md-33-proc {
    flex-basis: 33%;
  }
  .form-row.w-md-34-proc {
    flex-basis: 34%;
  }
  .form-row.w-md-35-proc {
    flex-basis: 35%;
  }
  .form-row.w-md-36-proc {
    flex-basis: 36%;
  }
  .form-row.w-md-37-proc {
    flex-basis: 37%;
  }
  .form-row.w-md-38-proc {
    flex-basis: 38%;
  }
  .form-row.w-md-39-proc {
    flex-basis: 39%;
  }
  .form-row.w-md-40-proc {
    flex-basis: 40%;
  }
  .form-row.w-md-41-proc {
    flex-basis: 41%;
  }
  .form-row.w-md-42-proc {
    flex-basis: 42%;
  }
  .form-row.w-md-43-proc {
    flex-basis: 43%;
  }
  .form-row.w-md-44-proc {
    flex-basis: 44%;
  }
  .form-row.w-md-45-proc {
    flex-basis: 45%;
  }
  .form-row.w-md-46-proc {
    flex-basis: 46%;
  }
  .form-row.w-md-47-proc {
    flex-basis: 47%;
  }
  .form-row.w-md-48-proc {
    flex-basis: 48%;
  }
  .form-row.w-md-49-proc {
    flex-basis: 49%;
  }
  .form-row.w-md-50-proc {
    flex-basis: 50%;
  }
  .form-row.w-md-51-proc {
    flex-basis: 51%;
  }
  .form-row.w-md-52-proc {
    flex-basis: 52%;
  }
  .form-row.w-md-53-proc {
    flex-basis: 53%;
  }
  .form-row.w-md-54-proc {
    flex-basis: 54%;
  }
  .form-row.w-md-55-proc {
    flex-basis: 55%;
  }
  .form-row.w-md-56-proc {
    flex-basis: 56%;
  }
  .form-row.w-md-57-proc {
    flex-basis: 57%;
  }
  .form-row.w-md-58-proc {
    flex-basis: 58%;
  }
  .form-row.w-md-59-proc {
    flex-basis: 59%;
  }
  .form-row.w-md-60-proc {
    flex-basis: 60%;
  }
  .form-row.w-md-61-proc {
    flex-basis: 61%;
  }
  .form-row.w-md-62-proc {
    flex-basis: 62%;
  }
  .form-row.w-md-63-proc {
    flex-basis: 63%;
  }
  .form-row.w-md-64-proc {
    flex-basis: 64%;
  }
  .form-row.w-md-65-proc {
    flex-basis: 65%;
  }
  .form-row.w-md-66-proc {
    flex-basis: 66%;
  }
  .form-row.w-md-67-proc {
    flex-basis: 67%;
  }
  .form-row.w-md-68-proc {
    flex-basis: 68%;
  }
  .form-row.w-md-69-proc {
    flex-basis: 69%;
  }
  .form-row.w-md-70-proc {
    flex-basis: 70%;
  }
  .form-row.w-md-71-proc {
    flex-basis: 71%;
  }
  .form-row.w-md-72-proc {
    flex-basis: 72%;
  }
  .form-row.w-md-73-proc {
    flex-basis: 73%;
  }
  .form-row.w-md-74-proc {
    flex-basis: 74%;
  }
  .form-row.w-md-75-proc {
    flex-basis: 75%;
  }
  .form-row.w-md-76-proc {
    flex-basis: 76%;
  }
  .form-row.w-md-77-proc {
    flex-basis: 77%;
  }
  .form-row.w-md-78-proc {
    flex-basis: 78%;
  }
  .form-row.w-md-79-proc {
    flex-basis: 79%;
  }
  .form-row.w-md-80-proc {
    flex-basis: 80%;
  }
  .form-row.w-md-81-proc {
    flex-basis: 81%;
  }
  .form-row.w-md-82-proc {
    flex-basis: 82%;
  }
  .form-row.w-md-83-proc {
    flex-basis: 83%;
  }
  .form-row.w-md-84-proc {
    flex-basis: 84%;
  }
  .form-row.w-md-85-proc {
    flex-basis: 85%;
  }
  .form-row.w-md-86-proc {
    flex-basis: 86%;
  }
  .form-row.w-md-87-proc {
    flex-basis: 87%;
  }
  .form-row.w-md-88-proc {
    flex-basis: 88%;
  }
  .form-row.w-md-89-proc {
    flex-basis: 89%;
  }
  .form-row.w-md-90-proc {
    flex-basis: 90%;
  }
  .form-row.w-md-91-proc {
    flex-basis: 91%;
  }
  .form-row.w-md-92-proc {
    flex-basis: 92%;
  }
  .form-row.w-md-93-proc {
    flex-basis: 93%;
  }
  .form-row.w-md-94-proc {
    flex-basis: 94%;
  }
  .form-row.w-md-95-proc {
    flex-basis: 95%;
  }
  .form-row.w-md-96-proc {
    flex-basis: 96%;
  }
  .form-row.w-md-97-proc {
    flex-basis: 97%;
  }
  .form-row.w-md-98-proc {
    flex-basis: 98%;
  }
  .form-row.w-md-99-proc {
    flex-basis: 99%;
  }
  .form-row.w-md-100-proc {
    flex-basis: 100%;
  }
}
.nav-cart-process {
  display: none;
}
.nav-cart-process .cart-process__item__backlink {
  font-size: 14px;
  display: block;
  color: #333;
  font-weight: 600;
}

.mobile-cart-process {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cart-process {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}
.cart-process .cart-process__sp {
  flex-grow: 1;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
}
.cart-process .cart-process__sp:after {
  content: "";
  display: block;
  height: 0;
  border-bottom: 1px solid #eee;
  width: 100%;
}
.cart-process .cart-process__sp:last-child:after {
  display: none;
}
.cart-process .cart-process__item {
  position: relative;
  background-color: #fff;
  font-size: 10px;
  text-align: center;
  color: #ccc;
  flex-grow: 0;
}
.cart-process .cart-process__item .cart-process__item__icon {
  border: 1px solid #ccc;
  display: flex;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.cart-process .cart-process__item .cart-process__item__icon svg {
  color: #ccc;
  width: 24px;
  height: 24px;
}
.cart-process .cart-process__item.is-selected {
  color: #198754;
  font-weight: 600;
}
.cart-process .cart-process__item.is-selected .cart-process__item__icon {
  border-color: #198754;
  color: #198754;
}
.cart-process .cart-process__item.is-selected .cart-process__item__icon svg {
  color: #198754;
}

@media (min-width: 992px) {
  .mobile-cart-process {
    display: none;
  }

  .nav-cart-process {
    display: block !important;
  }

  .cart-process .cart-process__sp {
    padding-bottom: 0px;
  }
  .cart-process .cart-process__item {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .cart-process .cart-process__item .cart-process__item__icon {
    margin-right: 10px;
    display: inline-flex;
  }
}
.c-search-term-filter {
  padding: 10px;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 0px;
  border: 1px solid #aaa;
  color: #333;
  cursor: pointer;
}
.c-search-term-filter .c-search-term-filter__term {
  font-weight: 700;
}
.c-search-term-filter .c-search-term-filter__remove svg {
  width: 16px;
  height: 16px;
  vertical-align: -3px;
}
.c-search-term-filter:hover {
  text-decoration: line-through;
}

.c-user-dropdown {
  padding: 15px;
}
.c-user-dropdown .c-user-dropdown_reg {
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  font-size: 14px;
}
.c-user-dropdown .c-user-dropdown_reg a {
  display: block;
  color: #333;
  font-weight: 600;
}
.c-user-dropdown .c-user-dropdown_reg a:hover {
  text-decoration: underline;
}

.c-cart {
  margin-top: 40px;
}
.c-cart .c-cart__item {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}
.c-cart .c-cart__item .loader {
  display: none;
}
.c-cart .c-cart__item.is-loading .loader {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.c-cart .c-cart__item.is-loading .loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  color: #222;
  font-size: 6px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: load4 1.3s infinite linear;
  transform: translateZ(0);
  display: block;
}
.c-cart .c-cart__item .c-cart__item_qunat_wrap {
  margin-top: 10px;
}
.c-cart .c-cart__item .c-cart__price_wrap {
  text-align: right;
  margin-top: 10px;
}
.c-cart .c-cart__item .c-cart__item__price {
  font-size: 18px;
  color: #222;
}
.c-cart .c-cart__item .c-cart__item__price b, .c-cart .c-cart__item .c-cart__item__price strike {
  white-space: nowrap;
}
.c-cart .c-cart__item .c-cart__item__price .c-cart__item__price__old {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}
.c-cart .c-cart__item .c-cart__item__price .c-cart__item__price__quantity {
  font-size: 14px;
  color: #666;
}
.c-cart .c-cart__item .c_cart__item__remove {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  vertical-align: 4px;
}
.c-cart .c-cart__item .c_cart__item__remove:hover {
  color: #333;
}
.c-cart .c-cart__item .c_cart__item__remove svg {
  color: #999;
  pointer-events: none;
  width: 24px;
  height: 24px;
}
.c-cart .c-cart__item .c-cart__item__label {
  font-size: 18px;
  font-weight: 500;
  color: #222;
}
.c-cart .c-cart__item .c-cart__item__label:hover {
  text-decoration: underline;
}
.c-cart .c-cart__item .c-cart__item__sublabel {
  font-size: 14px;
  color: #888;
  font-weight: 400;
}
.c-cart .c-cart__item .c-cart__item__tag {
  font-size: 13px;
  font-weight: 400;
  padding: 0.3rem 0.7rem;
  background-color: #676767;
  color: #fff;
  display: inline-block;
}

@media (min-width: 768px) {
  .c-cart .c-cart__item .c-cart__item_qunat_wrap {
    text-align: right;
  }
  .c-cart .c-cart__item .c-cart__item__price {
    margin-top: 10px;
  }
}
.c-cart-dropdown {
  font-size: 11px;
  width: 400px !important;
}
.c-cart-dropdown .c-cart-dropdown__item {
  padding-bottom: 10px;
}
.c-cart-dropdown .c-cart-dropdown__item__label {
  color: #222;
  font-weight: 500;
  font-size: 13px;
}
.c-cart-dropdown .c-cart-dropdown__item__label:hover {
  text-decoration: underline;
}

.c-cart-summary {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.c-cart-summary .c-cart-summary__free-ship {
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
}
.c-cart-summary .c-cart-summary__free-ship .c-cart-summary__free-ship__info {
  font-size: 14px;
}
.c-cart-summary .c-cart-summary__free-ship .c-cart-summary__free-ship__missing {
  font-size: 16px;
}
.c-cart-summary .c-cart-summary__free-ship .c-cart-summary__free-ship__missing b {
  font-weight: 600;
}
.c-cart-summary .c-cart-summary__content {
  margin-bottom: 20px;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item {
  margin-top: 10px;
  font-size: 16px;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item .c-cart-summary__item__plus_shipping {
  font-size: 12px;
  color: #999;
  font-weight: 500;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item .c-cart-summary__item__value {
  font-weight: 600;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item .c-cart-summary__item__value .c-cart-summary__item__value__old {
  font-weight: 500;
  color: #999;
  text-decoration: line-through;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item.is-sum {
  padding-top: 10px;
  border-top: 1px solid #eee;
  font-weight: 600;
}
.c-cart-summary .c-cart-summary__content .c-cart-summary__item.is-sum .c-cart-summary__item__value {
  font-size: 24px;
  font-weight: 700;
}
.c-cart-summary .c-cart-summary__head {
  font-size: 18px;
  font-weight: 500;
}
.c-cart-summary .c-cart-summary__sum {
  margin: 10px 0;
  font-size: 28px;
  font-weight: bold;
}
.c-cart-summary .c-cart-summary__sum .c-cart-summary__sum__old {
  font-size: 16px;
  color: #999;
  text-decoration: line-through;
}
.c-cart-summary .c-cart-summary__accept {
  margin-bottom: 20px;
}
.c-cart-summary .c-cart-summary__policy {
  margin-top: 20px;
  font-size: 13px;
  color: #666;
}
.c-cart-summary .c-cart-summary__policy .c-cart-summary__policy__head {
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
.c-cart-summary .c-cart-summary__policy .c-cart-summary__policy__text {
  font-weight: 400;
  height: 130px;
  overflow: auto;
  border: 1px solid #ddd;
  padding: 10px 15px;
}

@media (min-width: 768px) {
  .c-cart-summary {
    padding: 20px;
    position: sticky;
    top: 20px;
    border: 1px solid #eee;
  }
}
.c-checkbox-link {
  color: #333;
  font-size: 14px;
}
.c-checkbox-link:hover, .c-checkbox-link:focus {
  color: #333;
}
.c-checkbox-link:hover .ionicon, .c-checkbox-link:focus .ionicon {
  color: #666;
}
.c-checkbox-link .ionicon {
  color: #999;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  line-height: 24px;
  vertical-align: -7px;
}
.c-checkbox-link.is-checked:hover .ionicon {
  color: rgba(118, 12, 12, 0.5);
  fill: rgba(118, 12, 12, 0.5);
}
.c-checkbox-link.is-checked .ionicon {
  color: #760c0c;
  fill: #760c0c;
}

.c-customer-data {
  font-size: 14px;
  color: #333;
}
.c-customer-data .c-customer-data__name {
  font-size: 14px;
  font-weight: 500;
}
.c-customer-data .c-customer-data__sect {
  margin-top: 5px;
}
.c-customer-data .c-customer-data__sect .c-customer-data__sect__header {
  font-size: 12px;
  color: #aaa;
}

.c-summary-product {
  border-bottom: 1px solid #eee;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
}
.c-summary-product:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.c-summary-product .c-summary-product__label {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.c-summary-product .c-summary-product__sublabel {
  font-size: 13px;
  font-weight: 500;
  color: #666;
}
.c-summary-product .c-summary-product__tag {
  font-size: 11px;
  font-weight: 400;
  padding: 0.2rem 0.5rem;
  background-color: #676767;
  color: #fff;
  display: inline-block;
}
.c-summary-product .c-summary-product__price {
  font-size: 14px;
  text-align: right;
}
.c-summary-product .c-summary-product__price b {
  font-weight: 600;
}
.c-summary-product .c-summary-product__price .c-summary-product__price__quantity {
  font-size: 13px;
}
.c-summary-product .c-summary-product__price .c-summary-product__price__old {
  font-size: 11px;
  text-decoration: line-through;
  color: #999;
}

.c-radio-list a.c-radio-item:hover {
  background-color: #fafafa;
  color: #111;
}
.c-radio-list .c-radio-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #333;
}
.c-radio-list .c-radio-item .ionicon {
  color: #999;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  line-height: 24px;
  vertical-align: middle;
}
.c-radio-list .c-radio-item.is-selected .c-radio-item__label {
  font-weight: 600;
}
.c-radio-list .c-radio-item.is-selected .ionicon {
  color: #760c0c;
  fill: #760c0c;
}
.c-radio-list .c-radio-item.is-disabled {
  color: #ccc;
}
.c-radio-list .c-radio-item.is-disabled .ionicon {
  color: #ccc;
}

.c-shipping-method.is-selected .c-shipping-method__point {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.c-shipping-method .c-shipping-method__old_price {
  font-size: 12px;
  color: #999;
  text-decoration: line-through;
}

.c-order {
  margin-top: 40px;
}

.common-section {
  font-size: 12px;
  margin-top: 5em;
  margin-bottom: 5em;
}
.common-section.c-modal-content {
  margin-top: 0;
  margin-bottom: 0;
}
.common-section .c-section-title h2 {
  font-size: 1.5em;
}
.common-section .c-section-title p {
  font-size: 1em;
}
.common-section .c-section-under {
  margin-top: 1.5em;
  border-top: 1px solid #eee;
  padding-top: 1.5em;
  text-align: center;
}

@media (min-width: 768px) {
  .common-section {
    font-size: 14px;
  }
}
.app .splide.loading > * {
  display: none;
}
.app .splide:hover .splide__arrow {
  opacity: 0.6;
}
.app .splide .splide__arrow {
  background-color: transparent;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.app .splide .splide__arrow:hover {
  opacity: 0.9;
}
.app .splide .splide__arrow svg {
  width: 2em;
  height: 2em;
}
.app .splide .splide__arrow svg path {
  fill: white;
}
.app .splide .splide__pagination__page {
  width: 10px;
  height: 10px;
  background-color: #eee;
  border: 1px solid #aaa;
}
.app .splide .splide__pagination__page.is-active {
  width: 10px;
  height: 10px;
  transform: none;
  background: #fff;
  border: 1px solid #333;
}
.app .splide.blocks {
  padding-bottom: 40px;
}
.app .splide.blocks .splide__arrow {
  opacity: 0.2 !important;
}
.app .splide.blocks .splide__arrow--prev {
  left: 0;
}
.app .splide.blocks .splide__arrow--next {
  right: 0;
}
.app .splide.blocks .splide__arrow {
  background-color: transparent;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  top: calc(50% - 46px);
}
.app .splide.blocks .splide__arrow:hover {
  opacity: 0.9 !important;
}
.app .splide.blocks .splide__arrow svg {
  width: 2em;
  height: 2em;
}
.app .splide.blocks .splide__arrow svg path {
  fill: black;
}

@media (min-width: 992px) {
  .app .splide.blocks .splide__arrow--prev {
    left: -1.2rem;
  }
  .app .splide.blocks .splide__arrow--next {
    right: -1.2rem;
  }
}
.banner-slide {
  display: block;
  position: relative;
}
.banner-slide .banner-text-left {
  position: absolute;
  font-size: 12px;
  left: 10%;
  bottom: 10%;
}
.banner-slide .banner-text-right {
  position: absolute;
  font-size: 12px;
  left: 10%;
  bottom: 10%;
}

.category-image-box {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
  color: #fff;
}
.category-image-box .category-image-box__wrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.category-image-box .category-image-box__wrap:hover {
  color: #fff;
}
.category-image-box .category-image-box__wrap:hover img {
  width: 120%;
  transform: translateY(-10%) translateX(-5%);
}
.category-image-box .category-image-box__wrap:hover:before {
  background-color: rgba(0, 0, 0, 0.5);
}
.category-image-box .category-image-box__wrap:before {
  content: "";
  background-color: transparent;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}
.category-image-box .category-image-box__wrap:after {
  content: "";
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 11;
}
.category-image-box .category-image-box__wrap img {
  width: 100%;
  position: absolute;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.category-image-box .category-image-box__wrap .category-image-box__title {
  z-index: 20;
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.category-image-box .category-image-box__wrap .category-image-box__title .category-image-box__title__sub {
  font-weight: 400;
  display: block;
  font-size: 0.7em;
}

.category-cell {
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;
}
.category-cell img {
  max-width: 100%;
}
.category-cell .c-cell-category__name {
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
}
.category-cell:hover {
  border: 1px solid #999;
}

.c-cell-product {
  font-size: 14px;
  flex: 1 1 auto !important;
  padding: 1em;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eee;
  position: relative;
}
.c-cell-product.is-unavailable .c-cell-product__img {
  opacity: 0.5;
}
.c-cell-product.is-unavailable .c-cell-product__unavailable {
  font-size: 12px;
  color: #666;
  font-weight: 600;
}
.c-cell-product .c-cell-product__addcart {
  position: relative;
  z-index: 10;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  color: #333;
  cursor: pointer;
  margin-top: 0.6rem;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 0.5rem 0.7rem;
  text-align: center;
}
.c-cell-product .c-cell-product__addcart .c-cell-product__addcart-lbl {
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}
.c-cell-product .c-cell-product__addcart svg {
  width: 28px;
  height: 28px;
}
.c-cell-product .c-cell-product__addcart:hover {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}
.c-cell-product:hover .c-cell-product__addcart {
  visibility: visible;
  opacity: 1;
}
.c-cell-product a {
  text-decoration: none;
}
.c-cell-product .c-cell-product__img {
  width: 100%;
  flex-basis: 100%;
  flex-shrink: 0;
  display: flex;
  height: 160px;
  align-items: center;
  text-align: center;
  overflow: hidden;
}
.c-cell-product .c-cell-product__img img {
  max-height: 140px;
  max-width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.c-cell-product .c-cell-product__name .c-cell-product__name__name {
  font-weight: 600;
  font-size: 1em;
  color: #333;
}
.c-cell-product .c-cell-product__name .c-cell-product__name_sub {
  display: block;
  color: #666;
  font-size: 0.9em;
}
.c-cell-product .c-cell-product__name .c-cell-product__name__price {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
  margin-top: 0.5em;
}
.c-cell-product .c-cell-product__name .c-cell-product__name__price.is-discount {
  color: #ca0a0a;
}
.c-cell-product .c-cell-product__name .c-cell-product__name__price .c-cell-product__name__price_old {
  color: #999;
  display: inline-block;
  margin-left: 10px;
  text-decoration: line-through;
  font-weight: normal;
}
.c-cell-product:hover {
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .c-cell-product {
    font-size: 16px;
  }
  .c-cell-product .c-cell-product__img {
    height: 220px;
  }
  .c-cell-product .c-cell-product__img img {
    max-height: 200px;
  }
}
.c-breadcrumbs {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
}
.c-breadcrumbs a {
  color: #333;
  text-decoration: none;
}
.c-breadcrumbs span {
  color: #888;
}
.c-breadcrumbs svg {
  display: inline-block;
  height: 0.9em;
  width: 0.9em;
  margin: 0 6px;
  vertical-align: -2px;
  color: #aaa;
}

.c-categorytree {
  font-size: 14px;
}
.c-categorytree ul {
  padding-left: 15px;
}
.c-categorytree a {
  text-decoration: none;
}
.c-categorytree ul.level0 {
  margin-left: 0;
  padding-left: 0;
}
.c-categorytree a {
  color: #444;
  line-height: 20px;
}
.c-categorytree ul {
  list-style: none;
}
.c-categorytree li {
  padding: 5px 0;
}
.c-categorytree li.is-selected {
  font-weight: 600;
}
.c-categorytree .is-selected-leaf {
  color: #b12121;
}

.c-categorysubselector {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow-y: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-left: calc(var(--bs-gutter-x) * -1);
  margin-right: calc(var(--bs-gutter-x) * -1);
}
.c-categorysubselector:before {
  content: "";
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.c-categorysubselector:after {
  content: "";
  z-index: 3;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.c-categorysubselector .c-categorysubselector__wrapper {
  padding-bottom: 30px;
  margin-bottom: -30px;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-right: 20px;
}
.c-categorysubselector a {
  text-decoration: none;
  display: block;
  font-size: 14px;
  color: #333;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 18px 0 10px 0;
  margin-right: 10px;
  margin-left: 10px;
  border-bottom: 3px solid transparent;
  white-space: nowrap;
}
.c-categorysubselector a.is-selected {
  font-weight: 600;
  border-bottom: 3px solid #b12121;
}

@media (min-width: 768px) {
  .c-categorysubselector {
    display: none;
  }
}
.c-products-list__filters_row {
  display: none;
}

.c-mobile-filters-opened .c-products-list__filters_row {
  background-color: #fff;
  display: block !important;
  position: relative;
  margin-top: 20px;
}

.c-products-list__toolbar {
  margin-top: 20px;
}

.c-product-images {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.c-product-images .c-product-images__image {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  height: 200px;
  flex-grow: 1;
  width: 100%;
  cursor: zoom-in;
}
.c-product-images .c-product-images__image img {
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.c-product-images .c-product-images__thumbs {
  display: flex;
  width: 100%;
  order: 0;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.c-product-images .c-product-images__thumbs .c-product-images__thumbs__item {
  border: 1px solid #eee;
  max-height: 50px;
  width: 50px;
  margin-right: 10px;
  text-align: center;
  padding: 5px;
}
.c-product-images .c-product-images__thumbs .c-product-images__thumbs__item img {
  max-height: 40px;
  max-width: 40px;
}
.c-product-images .c-product-images__thumbs .c-product-images__thumbs__item.is-selected {
  border: 1px solid #333;
}

@media (min-width: 768px) {
  .c-product-images {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
    flex-direction: row;
  }
  .c-product-images .c-product-images__image {
    width: auto;
    order: 2;
    height: 400px;
  }
  .c-product-images .c-product-images__thumbs {
    flex-direction: column;
    width: auto;
  }
  .c-product-images .c-product-images__thumbs .c-product-images__thumbs__item {
    max-height: 100px;
    width: 100px;
    margin-bottom: 10px;
  }
  .c-product-images .c-product-images__thumbs .c-product-images__thumbs__item img {
    max-height: 80px;
    max-width: 80px;
  }
}
.c-product-codes {
  font-size: 13px;
  padding: 5px;
  font-weight: 500;
  color: #999;
}
.c-product-codes .c-product-codes__brand {
  font-weight: 700;
  color: #999;
}
.c-product-codes .c-product-codes__brand:hover {
  color: #333;
  text-decoration: underline;
}

.c-product-price .c-product-price__discount {
  color: #c70f0f;
  font-size: 14px;
}
.c-product-price .c-product-price__price {
  font-size: 22px;
  font-weight: bold;
  color: #222;
}
.c-product-price .c-product-price__price.is-discount {
  color: #c70f0f;
}
.c-product-price .c-product-price__price .c-product-price__price_old {
  color: #999;
  display: inline-block;
  margin-left: 10px;
  text-decoration: line-through;
  font-weight: normal;
}

.c-product-brand-image {
  max-width: 80px;
}

.c-product-label {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.c-product-vselect {
  margin-top: 20px;
  margin-bottom: 10px;
}
.c-product-vselect a {
  text-decoration: none;
}
.c-product-vselect .c-product-vselect__name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.c-product-vselect .c-product-vselect__item {
  font-size: 13px;
  display: inline-block;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #eee;
  color: #666;
}
.c-product-vselect .c-product-vselect__item:hover {
  border: 1px solid #666;
}
.c-product-vselect .c-product-vselect__item.is-current {
  border: 2px solid #333;
  padding: 8px;
  font-weight: bold;
  color: #222;
}

.c-product-addcart {
  display: flex;
  vertical-align: middle;
  margin-top: 30px;
}
.c-product-addcart .c-product-addcart__add button {
  cursor: pointer;
  font-weight: bold;
}

.c-quantity {
  display: inline-flex;
  border: 1px solid #ddd;
}
.c-quantity.is-inline {
  display: inline-flex;
}
.c-quantity.is-small input {
  font-size: 14px;
  padding: 6px 3px;
  width: 30px;
}
.c-quantity.is-small .c-quantity_inc, .c-quantity.is-small .c-quantity_dec {
  font-size: 22px;
  padding: 0px 6px;
}
.c-quantity input {
  border: 0px;
  outline: 0;
  width: 50px;
  text-align: center;
  font-size: 18px;
  padding: 10px 5px;
}
.c-quantity .c-quantity_inc, .c-quantity .c-quantity_dec {
  font-size: 28px;
  padding: 0px 10px;
  cursor: pointer;
  font-weight: bold;
  background-color: #fafafa;
  color: #999;
  user-select: none;
}
.c-quantity .c-quantity_inc:hover, .c-quantity .c-quantity_dec:hover {
  color: #333;
  background-color: #f0f0f0;
}

.c-micro-product {
  display: flex;
}
.c-micro-product .c-micro-product__img {
  display: block;
  width: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 15px;
}
.c-micro-product .c-micro-product__img img {
  max-width: 60px;
  max-height: 70px;
}
.c-micro-product .c-micro-product__data {
  flex-grow: 1;
  color: #333;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__name {
  font-size: 14px;
  font-weight: 600;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__sub {
  font-size: 11px;
  color: #999;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__price {
  display: flex;
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__price .c-micro-product__data__price__qant {
  justify-self: start;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__price .c-micro-product__price {
  justify-self: end;
  font-weight: 600;
  margin-left: auto;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__price .c-micro-product__price.is-discount {
  color: #ca0a0a;
}
.c-micro-product .c-micro-product__data .c-micro-product__data__price .c-micro-product__price .c-micro-product__price_old {
  display: inline-block;
  margin-left: 5px;
  color: #999;
  text-decoration: line-through;
}

.c-products-list__paginator {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-evenly;
}
.c-products-list__paginator .c-products-list__paginator_link {
  color: #333;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  font-size: 14px;
  align-self: stretch;
  display: flex;
  align-items: center;
}
.c-products-list__paginator .c-products-list__paginator_link svg {
  color: #666;
  margin: 0px 5px 2px 5px;
}
.c-products-list__paginator .c-products-list__paginator_link:hover svg {
  color: #333;
}
.c-products-list__paginator .c-products-list__paginator_link.is-disabled {
  opacity: 0.2;
}
.c-products-list__paginator .c-products-list__paginator_middle {
  display: flex;
}
.c-products-list__paginator .c-products-list__paginator_input {
  font-size: 14px;
  outline: 0;
  border: 1px solid transparent;
  width: 55px;
  height: 41px;
  line-height: 41px;
  text-align: center;
  margin-right: 3px;
  margin-left: 3px;
  font-weight: 700;
}
.c-products-list__paginator .c-products-list__paginator_input:focus {
  border-bottom: 1px solid #ddd;
}
.c-products-list__paginator .c-products-list__paginator_text {
  font-size: 14px;
  align-self: stretch;
  display: flex;
  align-items: center;
}
.c-products-list__paginator .c-products-list__paginator_text span {
  width: 55px;
  text-align: center;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (min-width: 768px) {
  .c-products-list__paginator {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.module-heading {
  font-size: 12px;
  padding-bottom: 1.8em;
  position: relative;
}
.module-heading .logo {
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% - 1.8em);
}
.module-heading .logo img {
  max-height: 100%;
}
.module-heading h1 {
  font-weight: 700;
  font-size: 2.3em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
.module-heading h2 {
  font-weight: 700;
  font-size: 1.6em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
.module-heading .brand-sub {
  font-weight: 500;
  color: #222;
  font-size: 1.4em;
}
.module-heading .sub {
  font-size: 1.9em;
  font-weight: 600;
  color: #666;
}
.module-heading .module-desc {
  font-size: 1em;
  color: #666;
}
.module-heading .module-desc p {
  margin-bottom: 0.4em;
}

h4 {
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 992px) {
  .module-heading {
    font-size: 14px;
  }

  h2.module-heading, .module-heading h2 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }
}
.c-profile__orders-list {
  font-size: 14px;
}
.c-profile__orders-list .c-profile__orders-list__header_row {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  font-weight: 500;
}
.c-profile__orders-list .c-profile__orders-list_row {
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.c-profile__order-details {
  font-size: 14px;
}
.c-profile__order-details .c-profile__order-details__item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__item__img {
  width: 8.3333333333%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__item__img img {
  max-width: 100%;
  max-height: 60px;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__name {
  flex-grow: 1;
  font-weight: 500;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__name .c-profile__order-details__subname {
  font-size: 12px;
  color: #666;
  font-weight: 400;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__price {
  text-align: right;
  padding-left: 20px;
  flex-shrink: 0;
}
.c-profile__order-details .c-profile__order-details__item .c-profile__order-details__price .c-cart__item__price__quantity {
  font-size: 12px;
}
.c-profile__order-details .c-profile__order-details__lbl {
  padding-top: 5px;
  font-size: 12px;
  color: #666;
}
.c-profile__order-details .c-profile__order-details__lbl span {
  display: block;
  font-size: 14px;
  color: #222;
}

@media (min-width: 992px) {
  .c-profile__order-details .c-profile__order-details__item .c-profile__order-details__price {
    width: auto;
    flex-shrink: 1;
  }
}
.cookie-alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 10px;
  z-index: 999999;
}
.cookie-alert .cookie-alert__h {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.cookie-alert .cookie-alert__h .cookie-alert__h-close {
  margin-left: auto;
}
.cookie-alert .cookie-alert__h .cookie-alert__h-close svg {
  color: #999;
}
.cookie-alert .cookie-alert__h .cookie-alert__h-close:hover svg {
  color: #000;
}
.cookie-alert .cookie-alert__text {
  font-size: 13px;
}
.cookie-alert .cookie-alert__links {
  font-size: 13px;
  margin-top: 10px;
}
.cookie-alert .cookie-alert__links a {
  color: #000;
}
.cookie-alert .cookie-alert__links a:hover {
  text-decoration: underline;
}
.cookie-alert .cookie-alert__mclose {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .cookie-alert {
    right: 10px;
    bottom: 10px;
    max-width: 400px;
  }
}
a {
  text-decoration: none;
}

picture {
  display: block;
  width: 100%;
}

picture img {
  width: 100%;
  height: auto;
}

.body-loading .body-loader {
  display: block;
}

.body-loader {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
}
.body-loader .body-loader__content {
  overflow: visible;
  display: inline-block;
  height: 100px;
  width: 100px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  padding-top: 34px;
  margin-top: calc(50vh - 50px);
}
.body-loader .body-loader__content svg {
  color: #198754;
  width: 80px;
}

.flash-notice {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 15px 0;
}

.show-cart-menu .c-menu {
  display: none;
}

.c-empty-placeholder {
  text-align: center;
}
.c-empty-placeholder img {
  display: block;
  max-width: 90%;
  width: 100px;
  margin: 0px auto 20px auto;
}

@media (min-width: 992px) {
  .c-empty-placeholder {
    text-align: center;
  }
  .c-empty-placeholder img {
    display: block;
    max-width: 90%;
    width: 200px;
    margin: 20px auto;
  }
}
.loader-block {
  display: inline-flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  padding: 30px 0;
  font-size: 13px;
}
.loader-block.is-block {
  display: block;
}
.loader-block svg {
  display: inline-block;
  width: 60px;
  margin-bottom: 20px;
}

.register-payment {
  text-align: center;
  margin: 1.2rem 0;
  border: 1px solid #ddd;
  background-color: #fafafa;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.9rem;
}
.register-payment .loader-block {
  padding: 0;
  margin-top: 1.2rem;
}
.register-payment .loader-block svg {
  width: 40px;
  margin-bottom: 0;
}
.register-payment p:last-child {
  margin-bottom: 0;
}

.text-page-content table + table {
  margin-top: 2rem;
}
.text-page-content table {
  border: 1px solid #aaa;
  width: 100%;
  border-collapse: collapse;
}
.text-page-content table p {
  margin-bottom: 0;
}
.text-page-content table td, .text-page-content table th {
  padding: 0.7em;
  border-right: 1px solid #aaa;
}
.text-page-content table tr {
  border-bottom: 1px solid #aaa;
}