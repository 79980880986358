
.c-shadow-block {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  .c-shadow-block__header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }



  .c-shadow-block__errors{
    list-style: none;
    padding: 10px;
    font-weight: 600;
    background-color: #b80000;
    margin: 10px 0;
    color: #fff;
  }

}

@include media-breakpoint-up(md) {
  .c-shadow-block {
    padding: 20px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    &+.c-shadow-block {
      margin-top: 30px;
    }
  }
}


.u-global-error {
  padding: 10px;
  border: 1px solid #b80000;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 2em;
  background-color: #b80000;
  color: #fff;
  font-weight: 600;
}