.c-products-list__paginator {

  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-evenly;


  .c-products-list__paginator_link {
    color: #333;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    font-size: 14px;
    align-self: stretch;

    display: flex;
    align-items: center;

    svg {
      color: #666;
      margin:0px 5px 2px 5px;
    }

    &:hover {
      svg {
        color: #333;
      }
    }

    &.is-disabled {
      opacity: 0.2;
    }
  }
  .c-products-list__paginator_middle {
    display: flex;
  }

  .c-products-list__paginator_input {
    font-size: 14px;
    outline:0;
    border: 1px solid transparent;
    width:55px;
    height: 41px;
    line-height: 41px;
    text-align: center;
    margin-right: 3px;
    margin-left: 3px;
    font-weight: 700;

    &:focus {
      border-bottom: 1px solid #ddd;
    }
  }

  .c-products-list__paginator_text {
    font-size: 14px;
    align-self: stretch;
    display: flex;
    align-items: center;

    span {
      width:55px;
      text-align: center;
    }
  }
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@include media-breakpoint-up(md) {
  .c-products-list__paginator {
    margin-top:0;
    margin-bottom: 0;
  }
}
