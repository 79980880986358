.nav-cart-process {
  display: none;

  .cart-process__item__backlink {
    font-size: 14px;
    display: block;
    color: #333;
    font-weight: 600;
  }
}

.mobile-cart-process {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cart-process {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  .cart-process__sp {
    flex-grow: 1;
    position: relative;
    padding-left:10px;
    padding-right: 10px;
    padding-bottom: 12px;
    &:after {
      content: '';
      display: block;
      height:0;
      border-bottom: 1px solid #eee;
      width: 100%;
    }

    &:last-child {
      &:after{
        display: none;
      }
    }
  }



  .cart-process__item {
    position: relative;
    background-color: #fff;
    font-size: 10px;
    text-align: center;
    color: #ccc;
    flex-grow: 0;



    .cart-process__item__icon {
      border: 1px solid #ccc;
      display: flex;
      width:40px;
      height:40px;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      justify-content: center;
      align-items: center;
      svg {
        color: #ccc;
        width: 24px;
        height: 24px;
      }
    }

    &.is-selected {
      color: #198754;
      font-weight: 600;
      .cart-process__item__icon {
        border-color: #198754;
        color: #198754;
        svg {
          color: #198754;
        }
      }
    }

  }

}



@include media-breakpoint-up(lg) {

  .mobile-cart-process {
    display: none;
  }

  .nav-cart-process {
    display: block !important;
  }

  .cart-process {

    .cart-process__sp {

      padding-bottom: 0px;
    }

    .cart-process__item {
      font-size: 14px;
      display: flex;
      align-items: center;
      .cart-process__item__icon {
        margin-right: 10px;
        display: inline-flex;
      }
    }
  }
}