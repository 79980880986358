.common-section {
  font-size: 12px;

  margin-top: 5em;
  margin-bottom: 5em;

  &.c-modal-content {
    margin-top: 0;
    margin-bottom: 0;
  }

  .c-section-title {
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }

  .c-section-under {
    margin-top: 1.5em;
    border-top: 1px solid #eee;
    padding-top: 1.5em;
    text-align: center;
  }
}

@include media-breakpoint-up(md){
  .common-section {
    font-size: 14px;

  }
}

.app {
  .splide {

    &.loading > * {
      display: none;
    }


    &:hover {
      .splide__arrow {
        opacity: 0.6;
      }
    }

    .splide__arrow {
      background-color: transparent;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.9;
      }
      svg {
        width: 2em;
        height: 2em;
      }
      svg path {
        fill: rgba(255,255,255,1);
      }
    }

    .splide__pagination__page {
      width: 10px;
      height: 10px;
      background-color: #eee;
      border: 1px solid #aaa;

      &.is-active {
        width: 10px;
        height: 10px;
        transform: none;
        background: #fff;
        border: 1px solid #333;
      }
    }

  }

  .splide.blocks {
    padding-bottom:40px;

    .splide__arrow {
      opacity: 0.2 !important;
    }

    .splide__arrow--prev {
      left: 0;
    }
    .splide__arrow--next {
      right: 0;
    }


    .splide__arrow {
      background-color: transparent;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      top: calc(50% - 46px);

      &:hover {
        opacity: 0.9 !important;
      }
      svg {
        width: 2em;
        height: 2em;
      }
      svg path {
        fill: rgba(0,0,0,1);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .app {
    .splide.blocks {
      .splide__arrow--prev {
        left:-1.2rem;
      }
      .splide__arrow--next {
        right:-1.2rem;
      }
    }
  }
}


.banner-slide {
  display: block;
  position: relative;

  .banner-text-left {
    position: absolute;
    font-size: 12px;
    left: 10%;
    bottom: 10%;
  }

  .banner-text-right {
    position: absolute;
    font-size: 12px;
    left: 10%;
    bottom: 10%;
  }
}