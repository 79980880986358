
.c-radio-list {

  a.c-radio-item {
    &:hover {
      background-color: #fafafa;
      color: #111;
    }
  }

  .c-radio-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #333;

    .ionicon {
      color: #999;
      width: 24px;
      height: 24px;
      margin-right: 20px;
      line-height: 24px;
      vertical-align: middle;
    }

    &.is-selected {
      .c-radio-item__label{
        font-weight: 600;
      }

      .ionicon {
        color: $accentDarkColor;
        fill: $accentDarkColor;
      }
    }

    &.is-disabled {
      color: #ccc;
      .ionicon {
        color: #ccc;
      }
    }

  }

}