.c-user-dropdown{
  padding: 15px;

  .c-user-dropdown_reg {
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 10px;
    font-size: 14px;

    a {
      display: block;
      color: #333;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}