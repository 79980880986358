.app-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .loader {
    display: none;
  }

  &.is-loading {
    position: relative;
    .loader {
      display: block;
      position: absolute;
      background-color: rgba(255,255,255,0.6);
      top:0;
      left:0;
      right:0;
      bottom:0;

      &:after {
        position: absolute;
        top:50%;
        left:50%;
        content:'';
        color: #222;
        font-size: 6px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        text-indent: -9999em;

        animation: load4 1.3s infinite linear;

        transform: translateZ(0);
        display: block;
      }

    }

  }


  //errors
  ul {
    color: #b80000;
    list-style: none;
    font-weight: 600;
    margin-left: 0;
    font-size: 13px;
    margin-bottom: 30px;
    border: 1px solid #b80000;
    padding: 10px;
  }

  .form-row-submit {
    flex-basis: 100%;
    text-align: center;

    a {
      color: #333;
      margin: 20px 0 10px 0;
      text-align: center;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .checkbox-form-row {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    label {
      margin-bottom: 0 !important;
      margin-left: 10px;
    }
  }

  .form-row {
    flex-basis: 100%;
    margin-bottom: 20px;

    @for $i from 1 through 100 {
      &.w-#{$i}-proc {
        flex-basis: $i * 1%;
      }
    }

    label {
      display: block;
      font-weight: 500;
      color: #333;
      font-size: 13px;
      margin-bottom: 5px;
    }

    textarea {
      height: 80px !important;
    }

    input[type="text"], input[type="password"], input[type="email"], select, textarea{
      border: 1px solid #ddd;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      width: 100%;
      padding-left: 10px;
      display: block;
      outline: 0;
      color: #000;

      &:focus {
        border: 1px solid #666;
      }

      &:disabled {
        border: 1px solid #ddd;
        background-color: #e5e5e5;
        color: #777;
      }
    }

    .help-text {
      color: #333;
      font-size: 12px;
      padding: 5px 0;
    }

    ul {
      margin-bottom: 0px;
      border: 0;
      padding: 0;
    }
  }



  .form-radio-row {
    label {
      font-size: 15px;
      color: #000;
      display: inline-block;
      margin-right: 20px;
      margin-left: 5px;
    }
  }

}


@include media-breakpoint-up(sm) {
  .form-row {
    @for $i from 1 through 100 {
      &.w-sm-#{$i}-proc {
        flex-basis: $i * 1%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .form-row {
    @for $i from 1 through 100 {
      &.w-md-#{$i}-proc {
        flex-basis: $i * 1%;
      }
    }
  }
}
