.categories-section {
  .categories-section__name {

  }
}


.category-image-box {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;

  color: #fff;

  .category-image-box__wrap {
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    height: 100%;

    &:hover {
      color: #fff;

      .category-image-box__title {

      }

      img {
        width: 120%;
        transform: translateY(-10%) translateX(-5%);
      }

      &:before {
        background-color: rgba(0,0,0,0.5);
      }

    }

    &:before {
      content: '';
      background-color: transparent;
      position: absolute;
      bottom:0;
      top:0;
      width: 100%;
      transition: all 0.2s ease-in-out;
      z-index: 10;
    }

    &:after {
      content: '';
      //background-image: linear-gradient(to top, rgba(255,255,255, 0.4), rgba(255,255,255, 0.1));
      background-image: linear-gradient(to top, rgba(0,0,0, 0.3), rgba(0,0,0, 0));
      position: absolute;
      bottom:0;
      top:0;
      width: 100%;
      transition: all 0.2s ease-in-out;
      z-index: 11
    }

    img {
      width: 100%;
      position: absolute;
      transition: all 0.2s ease-in-out;
      z-index: 1;
    }

    .category-image-box__title {
      z-index: 20;
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 18px;
      font-weight: 600;
      transition: all 0.2s ease-in-out;

      .category-image-box__title__sub {
        font-weight: 400;
        display: block;
        font-size: 0.7em;
      }
    }
  }



}

