.c-products-list__filters_row {
  display: none;

}

.c-mobile-filters-opened {

  .c-products-list__filters_row {
    background-color: #fff;
    display: block !important;
    position: relative;
    margin-top: 20px;

  }

}

.c-products-list__toolbar {
  margin-top: 20px;
}