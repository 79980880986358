

.c-cart-summary {

  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;

  .c-cart-summary__free-ship {
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;

    .c-cart-summary__free-ship__info {
      font-size: 14px;

    }
    .c-cart-summary__free-ship__missing {
      font-size: 16px;
      b {
        font-weight: 600;
      }
    }
  }

  .c-cart-summary__content {
    margin-bottom: 20px;
    .c-cart-summary__item {
      margin-top: 10px;
      font-size: 16px;

      .c-cart-summary__item__plus_shipping {
        font-size: 12px;
        color: #999;
        font-weight: 500;
      }

      .c-cart-summary__item__value {
        font-weight: 600;

        .c-cart-summary__item__value__old {
          font-weight: 500;
          color: #999;
          text-decoration: line-through;
        }
      }

      &.is-sum {
        padding-top: 10px;
        border-top: 1px solid #eee;
        font-weight: 600;
        .c-cart-summary__item__value {
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }

  .c-cart-summary__head {
    font-size: 18px;
    font-weight: 500;
  }

  .c-cart-summary__sum {
    margin:10px 0;
    font-size: 28px;
    font-weight: bold;

    .c-cart-summary__sum__old {
      font-size: 16px;
      color: #999;
      text-decoration: line-through;
    }
  }

  .c-cart-summary__accept {
    margin-bottom: 20px;
  }

  .c-cart-summary__policy {
    margin-top: 20px;
    font-size: 13px;
    color: #666;
    .c-cart-summary__policy__head {
      color: #333;
      font-size: 14px;
      font-weight: 600;
    }

    .c-cart-summary__policy__text {
      font-weight: 400;
      height: 130px;
      overflow: auto;
      border: 1px solid #ddd;
      padding: 10px 15px;
    }

  }
}


@include media-breakpoint-up(md) {
  .c-cart-summary {
    padding: 20px;
    position: sticky;
    top: 20px;
    border: 1px solid #eee;
  }
}