//górny pasek
@import "toppanel";

//panel nawigacji
@import "navpanel";

header {
  border-bottom: 1px solid #ebeef3;
}

@include media-breakpoint-up(lg) {
  header {
  }
}