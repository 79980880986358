.c-profile__order-details {
  font-size: 14px;

  .c-profile__order-details__item {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    .c-profile__order-details__item__img {
      width: 8.3333333333334%;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 60px;
      }
    }

    .c-profile__order-details__name {
      flex-grow: 1;
      font-weight: 500;
      .c-profile__order-details__subname {
        font-size: 12px;
        color: #666;
        font-weight: 400;
      }
    }

    .c-profile__order-details__price {
      text-align: right;
      padding-left: 20px;
      flex-shrink: 0;

      .c-cart__item__price__quantity {
        font-size: 12px;
      }
    }
  }


  .c-profile__order-details__lbl {
    padding-top: 5px;
    font-size: 12px;
    color: #666;
    span {
      display: block;
      font-size: 14px;
      color: #222;
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-profile__order-details {
    .c-profile__order-details__item {
      .c-profile__order-details__price {
        width: auto;
        flex-shrink: 1;
      }
    }
  }
}