
header {
  .top-panel {
    font-size: 0.8rem;
    background-color: $gray-100;
    padding: 0.8rem 0;

    a {
      color: #222;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: $accentColor;

        .ionicon {
          color: $accentColor;
        }
      }

      .ionicon {
        vertical-align: -6px;
        height: 22px;
      }
    }
  }
}