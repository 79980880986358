.c-border-block {
  padding: 10px;
  border: 1px solid #b7b7b7;

  &.is-selected {
    border: 1px solid #888;
    outline: 1px solid #888;
  }

  &.is-selectable {
    cursor: pointer;
    &:hover {
      border: 1px solid $accentColor;
    }
  }
}