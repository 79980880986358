
.c-cart-menu-footer {
  background-color: #fafafa;
  padding:1rem 0;

  a {
    .ionicon {
      width: 16px;
      height: 16px;
      color: #666;
      vertical-align: -0.18em;
    }
    color: #666;

    &:hover {
      color: #000;
      .ionicon {
        color: #000;
      }
    }
  }
}

.layout-footer {
  margin-top: 20px;
}

.footer-container {
  font-size: 16px;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #f5f5f5;
}




.footer-block {
  margin-top: 1em;
  margin-bottom: 2em;
  line-height: 2em;

  &.is-collapsible {
    margin-top: -1px;
    border-top: 1px solid #ddd;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;

    .footer-block__content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }

    .footer-block__header {
      font-size: 1.0em;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 1.6em;

      position: relative;

      .open-ind {
        position: absolute;
        height: 16px;
        width: 16px;
        right: 10px;
        top: 5px;
        transform: rotate(90deg);
        transition: all 0.3s ease-in-out;
      }
    }

    &.is-opened {
      .footer-block__header {
        .open-ind {
          transform: rotate(270deg);
        }
      }
    }
  }

  .footer-block__header {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0.1em;
  }

  .footer-block__content {

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    a {
      color: #333;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

  }
}


@include media-breakpoint-up(lg) {
  footer {
    font-size: 14px;
  }
  .footer-block {

    &.is-collapsible {
      margin-bottom: 1em;
      padding: 0;
      border:0;
      .footer-block__content {
        max-height: none;
        overflow: visible;
      }

      .footer-block__header {
        font-size: 1.4em;
        margin-bottom: 0.2em;
        .open-ind {
          display: none;
        }
      }
    }
  }

}

.footer-icon-block {
  padding: 5px 0;
  display: flex;
  .ionicon {
    margin-right: 10px;
    margin-top: 2px;
    width: 24px;
    height: 24px;
  }
}

.footer-social-links {
  margin-top: 0.4em;

  .footer-social-links__link {
    margin-right: 32px;

    &:hover {
      text-decoration: none !important;
    }
  }

  .ionicon {
    width: 32px;
    height: 32px;
  }
}