.c-profile__orders-list {
  font-size: 14px;

  .c-profile__orders-list__header_row {
    align-items: center;

    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: 2px solid #ddd;
    font-weight: 500;
  }

  .c-profile__orders-list_row {
    align-items: center;

    padding-top: 10px;
    padding-bottom: 10px;

    border-bottom: 1px solid #ddd;

  }
}