.u-checkbox {

  position: relative;
  padding-left:30px;
  display: block;

  input[type=checkbox] {
    display: none;
  }

  input[type="checkbox"] + div {
    position:absolute;
    left: 0;
  }

  input[type="checkbox"] + div > svg{
    color: #999;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    line-height: 24px;
    vertical-align: -7px;
    display: inline-block;

    &.checked {
      display: none;
    }
  }

  input[type="checkbox"]:checked + div > svg {
    &.unchecked {
      display: none;
    }
    &.checked {
      display: inline-block;
    }
  }

}