
.c-summary-product{
  border-bottom: 1px solid #eee;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .c-summary-product__label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }

  .c-summary-product__sublabel {
    font-size: 13px;
    font-weight: 500;
    color: #666;
  }

  .c-summary-product__tag {
    font-size: 11px;
    font-weight: 400;
    padding: 0.2rem 0.5rem;
    background-color: #676767;
    color: #fff;
    display: inline-block;
  }

  .c-summary-product__price {
    font-size: 14px;
    text-align: right;
    b {
      font-weight: 600;
    }

    .c-summary-product__price__quantity {
      font-size: 13px;
    }

    .c-summary-product__price__old {
      font-size: 11px;
      text-decoration: line-through;
      color: #999;
    }
  }
}