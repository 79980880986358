
.c-checkbox-link {
  color: #333;
  font-size: 14px;

  &:hover, &:focus {
    color: #333;
    .ionicon {
      color: #666;
    }
  }

  .ionicon {
    color: #999;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    line-height: 24px;
    vertical-align: -7px;
  }

  &.is-checked {
    &:hover {
      .ionicon {
        color: rgba($accentDarkColor, 0.5);
        fill: rgba($accentDarkColor, 0.5);
      }
    }


    .ionicon {
      color: $accentDarkColor;
      fill: $accentDarkColor;
    }
  }

}