.comp-header-search {
  position: relative;

  .comp-header-search__wrapper {
    position: relative;
    overflow: visible;

    .comp-header-search__autocomplete__loader {
      display: none;
    }

    &.is-autocomplete-loading {

      .comp-header-search__autocomplete {
        min-height: 70px;
      }
      .comp-header-search__autocomplete__loader {
        display: block;
        z-index: 2;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 30px 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        svg {
          width: 50px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }

    .comp-header-search__autocomplete {
      display: none;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 15px;
      position: absolute;
      right:0;
      width: 800px;
      max-width: calc(100vw - 160px);
      z-index: 2000;



      .comp-header-search__autocomplete_bottom_row {
        text-align: center;
        margin-top: 20px;
        a {
          display: inline-block;
          margin-left:auto;
          margin-right: auto;
          color: #333;
          font-size: 14px;

          &:hover {
            text-decoration: underline;
          }

        }

      }

      .comp-header-search__autocomplete_title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .comp-header-search__autocomplete_list {
        border-top: 1px solid #eee;
        a {
          font-size: 13px;
          color: #666;
          display: block;
          border-bottom: 1px solid #eee;
          padding: 10px 5px;
          font-weight: 500;

          &:hover {
            color: #333333;
            text-decoration: underline;
          }
        }
      }

      .comp-header-search__autocomplete_products {
        border-top: 1px solid #eee;

        .comp-header-search__autocomplete_product {
          display: block;
          border-bottom: 1px solid #eee;
          padding:10px;

          &:hover, &.is-selected {
            background-color: #fafafa;
          }
        }
      }





    }

    .comp-header-search__form {
      position: relative;

      input.c-search__input {
        width: 300px;
        max-width: 100%;
        line-height: 40px;
        height: 40px;
        font-size: 14px;
        padding: 0 40px 0 10px;
        border: 1px solid #f3f3f3;
        background-color: #f3f3f3;
        outline: 0;
        transition: all 0.2s ease-in-out;
        &:focus {
          border: 1px solid #eee;
          background-color: #fff;
        }
      }

      input.c-search__input:not(:placeholder-shown) + .c-comp-header-search__submit {

        &:hover {
          svg {
            color: $accentColor;
          }
        }

        svg {
          color: #333;
        }
      }

      .c-comp-header-search__submit {
        position: absolute;
        right: 6px;
        top: 3px;

        svg {
          transition: all 0.2s ease-in-out;
          color: #999;
        }

      }

    }

  }

}

.comp-search-opened {

  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.6);
    z-index: 500;
  }

  .comp-header-search__wrapper {
    padding: 10px;
    position: fixed;
    height: 60px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 550;
    display: flex !important;
    align-items: center;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.2s ease-in-out;
    flex-wrap: wrap;

    &.c-menu-opened {
      opacity: 1;
      transform: none;
    }

    .comp-header-search__autocomplete {
      position: static;
      width: 100%;
      max-width: none;
      margin-top: 20px;
      max-height: calc(100vh - 140px);
      overflow: auto;
    }

    .comp-header-search__form {
      flex-grow: 1;
      margin-right: 20px;
      input.c-search__input {
        width: 100%;
      }
    }
  }


}