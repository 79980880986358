
.c-submenu {



  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  max-width: 100%;
  width: 600px;
  background-color: #fff;
  padding: 20px;
  z-index: 600;
  transition: all 0.3s ease-in-out;

  transform: translateX(-100%);

  .c-submenu__list {
    list-style: none;
    padding-left: 0;
    padding-bottom: 0;
    border-top: 1px solid #eee;

    .c-submenu__item_link {
      display: block;
      font-size: 16px;
      color: #333;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;
      border-bottom: 1px solid #eee;
      &.is-active {
        font-weight: 700;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }

  .c-submenu__pre_mobile {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .c-submenu__title {
      flex-grow: 1;
    }
    .svgbutton {
      flex-grow: 0;
      justify-self: end;
    }
  }

}

.sub-menu-open {

  overflow: hidden;

  &::before {
    content: '';
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.6);
    z-index: 500;
  }


  .c-submenu.c-menu-opened {
    transform: translateX(0);
  }

}


@include media-breakpoint-up(md) {
  .c-submenu {
    display: block !important;
    position: static !important;
    transform: none !important;
    padding:0;

    .c-submenu__list {
      .c-submenu__item_link {
        font-size: 14px;
      }
    }
  }


}