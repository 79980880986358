.c-cart-dropdown {
  font-size: 11px;
  width: 400px !important;

  .c-cart-dropdown__item {
    padding-bottom: 10px;

  }

  .c-cart-dropdown__item__label {
    color: #222;
    font-weight: 500;
    font-size: 13px;

    &:hover {
      text-decoration: underline;
    }
  }
}

