.category-cell {
  border: 1px solid #eee;
  transition: all 0.2s ease-in-out;

  img {
    max-width: 100%;
  }


  .c-cell-category__name {
    color: #333;
    font-size: 0.9rem;
    font-weight: 400;

    margin-top: 20px;
    text-align: center;
  }

  &:hover {
    border: 1px solid #999;
  }

}

