header {
  .nav-panel {

    .nav-panel__logo-link {
      display: block;
      padding-bottom: 10px;
      padding-top: 10px;

      img {
        height: 60px;
      }
    }

    .nav-panel__main {
      display: flex;
      flex-direction: column;

      > * {
        flex-basis: 100%;

        > .row {
          height: 100%;
        }
      }
    }


  }
}

//desktop
@include media-breakpoint-up(lg) {

  header {
    .nav-panel {
      .nav-panel__logo-link {
        img {
          height: 120px;
        }
      }

      .nav-panel__main {

        .nav-panel__menu {
          border-top: 1px solid #eee;
        }

        > * {
          flex-basis: 50%;
        }
      }
    }
  }

}