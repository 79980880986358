.c-modal-gmap {

  .c-modal-gmap__toolbar {

    margin-bottom: 10px;

    .c-modal-gmap__toolbar__search_input {
      border: 1px solid #ddd;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      width: 100%;
      padding-left: 10px;
      display: block;
      outline: 0;
    }

  }

  .c-modal-gmap__map-container {
    height: calc(100vh - 120px);
    max-height: 600px;
    width: 100%;
    position: relative;


    .c-modal-gmap__map-container__point {
      position: absolute;
      top: 10px;
      left: 10px;
      box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.4);
      padding: 10px;
      background-color: #fff;
      z-index: 2;
      min-width: 300px;
      max-width: 40%;

      .c-modal-gmap__map-container__point__close {
        float: right;
        cursor: pointer;
        &:hover {
          svg{
            color: #333;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          color: #aaa;
          pointer-events: none;
        }
      }

      .c-modal-gmap__map-container__point__name {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }

      .c-modal-gmap__map-container__point__type {
        color: #333;
        font-size: 13px;
        font-weight: 600;
      }

      .c-modal-gmap__map-container__point__address {
        margin-top: 10px;
        color: #333;
        font-size: 13px;
        font-weight: 400;

        max-width: 300px;
      }

      .c-modal-gmap__map-container__point__hours_title {
        font-size: 13px;
        font-weight: 600;
        color: #333;
        margin-top: 10px;
      }

      .c-modal-gmap__map-container__point__hours {
        font-size: 13px;
        font-weight: 400;
        color: #333;

        b{
          font-weight: 500;
        }
      }

      .c-modal-gmap__map-container__point__select {
        margin-top: 20px;
      }

    }

    .c-modal-gmap__map-container__map {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  &.loading {
    .c-modal-gmap__map-container::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

}

@media (max-width: 320px) {
  .c-modal-gmap {
    .c-modal-gmap__map-container {
      .c-modal-gmap__map-container__point {
        top:0;
        left:0;
        max-width: 100%;
        min-width: auto;
      }
    }
  }
}