.c-micro-product {
  display: flex;



  .c-micro-product__img {
    display: block;
    width: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 15px;
    img {
      max-width: 60px;
      max-height: 70px;
    }
  }

  .c-micro-product__data{
    flex-grow: 1;
    color: #333;
    .c-micro-product__data__name {
      font-size: 14px;
      font-weight: 600;
    }

    .c-micro-product__data__sub {
      font-size: 11px;
      color: #999;
    }

    .c-micro-product__data__price {
      display: flex;
      font-size: 12px;
      width:100%;
      margin-top:10px;

      .c-micro-product__data__price__qant {
        justify-self: start;
      }

      .c-micro-product__price {
        justify-self: end;
        font-weight: 600;
        margin-left:auto;

        &.is-discount {
          color: #ca0a0a;
        }

        .c-micro-product__price_old {
          display: inline-block;
          margin-left: 5px;
          color: #999;
          text-decoration: line-through;
        }
      }

    }

  }


}