


.c-product-images {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #eee;

  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .c-product-images__image {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    height: 200px;
    flex-grow: 1;
    width: 100%;
    cursor: zoom-in;

    img {
      max-height: 100%;
      max-width: 100%;
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-product-images__thumbs {

    display: flex;
    width: 100%;
    order: 0;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .c-product-images__thumbs__item {
      border: 1px solid #eee;
      max-height: 50px;
      width: 50px;
      margin-right: 10px;
      text-align: center;
      padding: 5px;

      img {
        max-height: 40px;
        max-width: 40px;
      }

      &.is-selected {
        border: 1px solid #333;
      }
    }
  }
}


@include media-breakpoint-up(md) {
  .c-product-images {

    padding-top: 0;
    margin-top: 0;
    border-top: 0;

    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;

    flex-direction: row;

    .c-product-images__image {
      width: auto;
      order: 2;
      height: 400px;
    }

    .c-product-images__thumbs {
      flex-direction: column;
      width: auto;

      .c-product-images__thumbs__item {
        max-height: 100px;
        width: 100px;
        margin-bottom: 10px;
        img {
          max-height: 80px;
          max-width: 80px;
        }
      }
    }
  }
}

.c-product-codes {
  font-size: 13px;
  padding: 5px;
  font-weight: 500;
  color: #999;

  .c-product-codes__brand {
    font-weight: 700;
    color: #999;
    &:hover {
      color:#333;
      text-decoration: underline;
    }
  }
}

.c-product-price {

  .c-product-price__discount {
    color: $priceDropColor;
    font-size: 14px;
  }

  .c-product-price__price{
    font-size: 22px;
    font-weight: bold;
    color: #222;

    &.is-discount {
      color: $priceDropColor;
    }

    .c-product-price__price_old {
      color: #999;
      display: inline-block;
      margin-left: 10px;
      text-decoration: line-through;
      font-weight: normal;
    }
  }

}

.c-product-brand-image {
  max-width: 80px;
}

.c-product-label {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}


.c-product-vselect {

  a {
    text-decoration: none;
  }

  margin-top: 20px;
  margin-bottom: 10px;
  .c-product-vselect__name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .c-product-vselect__items {

  }

  .c-product-vselect__item {
    font-size: 13px;

    display: inline-block;
    margin-right: 10px;
    padding:10px;
    border: 1px solid #eee;
    color: #666;

    &:hover{
      border: 1px solid #666;
    }

    &.is-current {
      border: 2px solid #333;
      padding: 8px;
      font-weight: bold;
      color: #222;
    }
  }
}


.c-product-addcart {
  display: flex;
  vertical-align: middle;
  margin-top: 30px;



  .c-product-addcart__add {
    button {
      cursor: pointer;
      font-weight: bold;
    }
  }

}


.c-quantity {
  display: inline-flex;
  border: 1px solid #ddd;

  &.is-inline {
    display: inline-flex;
  }

  &.is-small {
    input {
      font-size: 14px;
      padding: 6px 3px;
      width: 30px;
    }
    .c-quantity_inc, .c-quantity_dec {
      font-size: 22px;
      padding: 0px 6px;
    }

  }


  input {
    border: 0px;
    outline: 0;
    width: 50px;
    text-align: center;
    font-size: 18px;
    padding: 10px 5px;
  }

  .c-quantity_inc, .c-quantity_dec {
    font-size: 28px;
    padding: 0px 10px;
    cursor: pointer;
    font-weight: bold;
    background-color: #fafafa;
    color: #999;
    user-select: none;
    &:hover{
      color: #333;
      background-color: #f0f0f0;
    }
  }

}