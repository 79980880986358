.c-customer-data {
  font-size: 14px;
  color: #333;

  .c-customer-data__name {
    font-size: 14px;
    font-weight: 500;
  }

  .c-customer-data__sect {
    margin-top: 5px;
    .c-customer-data__sect__header {
      font-size: 12px;
      color: #aaa;
    }
  }

}