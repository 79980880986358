.cookie-alert {
  position: fixed;

  bottom: 0;

  width: 100%;

  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 10px;
  z-index: 999999;

  .cookie-alert__h {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;

    .cookie-alert__h-close {
      margin-left: auto;

      svg {
        color: #999;
      }

      &:hover{
        svg {
          color: #000;
        }
      }
    }
  }

  .cookie-alert__text {
    font-size: 13px;

  }

  .cookie-alert__links {
    font-size: 13px;
    margin-top: 10px;
    a {
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .cookie-alert__mclose {
    margin-top: 10px;
  }
}

@include media-breakpoint-up(md) {
  .cookie-alert {
    right: 10px;
    bottom: 10px;
    max-width: 400px;
  }
}