// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$font-family-sans-serif: 'Open Sans',  Helvetica, Roboto, Arial, sans-serif !default;

//$font-family-sans-serif: 'Inter',  Helvetica, Roboto, Arial, sans-serif !default;


$accentColor: #b12121;
$accentDarkColor: #760c0c;

$priceDropColor: #c70f0f;
// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

$utilities: (
  "display": (
    responsive: true,
    print: true,
    property: display,
    class: d,
    values: inline inline-block block grid table table-row table-cell flex inline-flex none
  ),
  "justify-content": (
          responsive: true,
          property: justify-content,
          values: (
              start: flex-start,
              end: flex-end,
              center: center,
              between: space-between,
              around: space-around,
              evenly: space-evenly,
          )
  ),
        "align-items": (
                responsive: true,
                property: align-items,
                values: (
                        start: flex-start,
                        end: flex-end,
                        center: center,
                        baseline: baseline,
                        stretch: stretch,
                )
        ),
        "align-self": (
                responsive: true,
                property: align-self,
                values: (
                        auto: auto,
                        start: flex-start,
                        end: flex-end,
                        center: center,
                        baseline: baseline,
                        stretch: stretch,
                )
        ),
  // Padding utilities
        "padding": (
                responsive: true,
                property: padding,
                class: p,
                values: $spacers
        ),
        "flex": (
                responsive: true,
                property: flex,
                values: (fill: 1 1 auto)
        ),
        "order": (
                responsive: true,
                property: order,
                values: (
                        first: -1,
                        0: 0,
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        last: 6,
                ),
        ),
);



@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";

@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";

@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";

body{
  background-color: #fff;

}

button.c-button, input[type="submit"].c-button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  //padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}

.ionicon {
  height: 24px;
  width: 24px;
  color: #333;
}

@import "utils/iconlink";
@import "utils/button";
@import "utils/checkbox";
@import "utils/progress";
@import "utils/shadow_block";

@import "header/header";
@import "footer/footer";

@import "components/header-menu";
@import "components/header-search";
@import "components/header-cart";
@import "components/reldrop";
@import "components/dropbutton";
@import "components/gallery-zoom";
@import "components/tabs";
@import "components/details";
@import "components/modal";
@import "components/border-block";
@import "components/gmap-modal";
@import "components/submenu";
@import "components/app-form";
@import "components/cart-process";
@import "components/search-term-filter";
@import "components/header-user";
@import "cart/cart";

@import "order/order";

@import "sections/sections";

@import "profile/orders-list";
@import "profile/order-details";
@import "components/cookie-alert";

a {
  text-decoration: none;
}

picture {
  display: block;
  width: 100%;
}

picture img {
  width: 100%;
  height: auto;
}

.body-loading {
  .body-loader {
    display: block;
  }
}

.body-loader {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding-top: 0;
  text-align: center;
  background-color: rgba(0,0,0,0.6);

  .body-loader__content {
    overflow: visible;
    display: inline-block;

    height: 100px;
    width: 100px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    padding-top: 34px;
    margin-top: calc(50vh - 50px);

    svg {
      color: #198754;
      width: 80px;
    }
  }
}

.flash-notice {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 15px 0;
}

.show-cart-menu .c-menu {
  display: none;
}

.c-empty-placeholder {
  text-align: center;

  img {
    display: block;
    max-width: 90%;
    width: 100px;
    margin: 0px auto 20px auto;
  }
}

@include media-breakpoint-up(lg) {

  .c-empty-placeholder {
    text-align: center;

    img {
      display: block;
      max-width: 90%;
      width: 200px;
      margin: 20px auto;
    }
  }

}

.loader-block {

  &.is-block {
    display: block;
  }

  display: inline-flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  padding: 30px 0;
  font-size: 13px;

  svg {
    display: inline-block;
    width: 60px;
    margin-bottom: 20px;
  }

}

.register-payment {
  text-align: center;
  margin: 1.2rem 0;
  border: 1px solid #ddd;
  background-color: #fafafa;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.9rem;

  .loader-block {
    padding:0;
    margin-top: 1.2rem;

    svg {
      width: 40px;
      margin-bottom: 0;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

}




.text-page-content {

  $tableBorder: 1px solid #aaa;

  table + table {
    margin-top: 2rem;
  }

  table {



    p {
      margin-bottom: 0;
    }

    border: $tableBorder;
    width:100%;
    border-collapse: collapse;

    td, th {
      padding: 0.7em;
      border-right: $tableBorder;
    }

    tr {
      border-bottom: $tableBorder;
    }

  }

}
