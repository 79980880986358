
$dropDropedBoderColor: #ccc;

$dropActiveBoderColor: #999;

.c-dropbutton {

  display: block;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-top: -2px;

  a {
    text-decoration: none;
  }


  &.is-dropped {
    z-index: 20;

    .c-dropbutton__dropdown {
      visibility: visible;
      pointer-events: all;
      background-color: #fff;
      height: auto;
      border-bottom: 1px solid #aaa;
    }

    &.is-dropped {
      .c-dropbutton__label {
        box-shadow:  inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #fff;
      }
    }

    .c-dropbutton__label {
      svg {
        transform: rotate(270deg);
      }
    }
  }

  .c-dropbutton__label {
    cursor: pointer;
    padding: 10px 10px 12px 10px;
    display: block;
    background-color: #fff;
    position: relative;

    box-shadow:  inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #aaa;

    z-index: 6;

    svg {
      color: #999;
      transition: all 0.2s ease-in-out;
      width: 16px;
      height: 16px;
      transform: rotate(90deg);
      vertical-align: -2px;
      margin-left: 10px;
      top: 14px;
      position: absolute;
      right: 10px;
    }

    .c-dropbutton__label__active {
      display: inline-block;
      background-color: #ddd;
      padding: 0px 5px;
    }

  }


  &.is-active {
    .c-dropbutton__dropdown {
      border-bottom: 1px solid #666;
    }
  }

  .c-dropbutton__dropdown {
    position: relative;
    visibility: hidden;
    pointer-events: none;
    width: 100%;
    margin-top: -1px;
    z-index: 5;
    height: 0;
    overflow: hidden;

    &.is-list {
      a {
        color: #333;
        display: block;
        padding: 8px;
        border-bottom: 1px solid #eee;

        &.is-selected {
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: #fafafa;
          color: $accentColor;
        }
      }
    }
  }


  .c-dropbutton__dropdown__range_row {
    display: flex;
    align-items: center;

    margin: 20px 10px;
    position: relative;

    .c-dropbutton__dropdown__range_separator {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 25px;
      border-bottom: 1px solid #ddd;
      height: 1px;
      margin: 0 10px;
    }

    .c-dropbutton__dropdown__input {
      flex-basis: 50%;
    }
  }

  .c-dropbutton__dropdown__input {
    position: relative;

    input {
      border: 1px solid #ddd;
      height: 30px;
      line-height: 15px;
      font-size: 14px;
      width: 100%;
      /* padding-left: 10px; */
      display: block;
      outline: 0;
      padding-left: 7px;
      padding-right: 40px;


      &:focus {
        border: 1px solid #666;
      }
    }

    .c-dropbutton__dropdown__input_unit {
      line-height: 32px;
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  .c-dropbutton__dropdown__checklist {

    max-height: 229px;
    overflow: auto;

    label {
      color: #333;
      display: block;
      padding: 8px;
      border-bottom: 1px solid #eee;

      > span {

        .icon {
          border: 1px solid #999;
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 5px;
          /* padding: 1px; */
          vertical-align: -3px;
        }

        svg {
          width: 16px;
          height: 16px;
          opacity: 0;
          color: #444;
          display: block;
        }
      }

      input {
        display: none;
      }

      input:checked + span {
        font-weight: bold;
        .icon {
          border: 1px solid #333;
        }
        svg {
          opacity: 1;
        }
      }
    }


  }

  .c-dropbutton__dropdown__tools {
    display: flex;
    padding: 10px;

    .c-dropbutton__dropdown__tools__clear {
      cursor: pointer;
      padding: 10px;
      font-size: 13px;
      flex-shrink: 1;
      flex-grow: 0;
      text-align: center;
      background-color: #eee;
      margin-right: 10px;
      &:hover {
        background-color: #ddd;
      }
    }

    .c-dropbutton__dropdown__tools__save {
      cursor: pointer;
      padding: 10px;
      font-size: 13px;
      flex-shrink: 1;
      flex-grow: 1;
      text-align: center;
      background-color: $accentColor;
      color: #fff;
      font-weight: bold;

      &:hover {
        background-color: $accentDarkColor;
      }
    }

  }


}


@include media-breakpoint-up(md) {

  .c-dropbutton {
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0px;


    .c-dropbutton__label {

      padding: 10px;

      display: inline-block;
      box-shadow: inset -1px 0 0 0 #aaa, inset 1px 0 0 0 #aaa, inset 0 1px 0 0 #aaa, inset 0 -1px 0 0 #aaa;
      svg {
        position: static;
      }
    }

    .c-dropbutton__dropdown {
      width: 0;
      position: absolute;
      border: 1px solid $dropDropedBoderColor;
      display: block;
    }

    &.is-right {
      .c-dropbutton__dropdown {
        right:0;
      }
    }

    &.is-dropped {

      .c-dropbutton__dropdown {
        width: 300px;
      }

      .c-dropbutton__label {
        box-shadow: inset -1px 0 0 0 $dropDropedBoderColor, inset 1px 0 0 0 $dropDropedBoderColor, inset 0 1px 0 0 $dropDropedBoderColor, inset 0 -1px 0 0 #fff !important;
      }
    }

    &.is-active {
      .c-dropbutton__label {
        box-shadow: inset -1px 0 0 0 $dropActiveBoderColor, inset 1px 0 0 0 $dropActiveBoderColor, inset 0 1px 0 0 $dropActiveBoderColor, inset 0 -1px 0 0 $dropActiveBoderColor;
      }

      &.is-dropped {
        .c-dropbutton__label {
          box-shadow: inset -1px 0 0 0 #999, inset 1px 0 0 0 #999, inset 0 1px 0 0 #999, inset 0 -1px 0 0 #fff;
        }
      }
    }

  }


}


.c-range-select {

  $rangeLaneColor: rgb(205, 205, 205);
  $rangeSelectedColor: rgb(183, 26, 26);

  margin: 20px 10px;
  height: 20px;
  position: relative;
  user-select: none;

  &::before{
    content: '';
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, $rangeLaneColor 45%, $rangeLaneColor 55%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 100%);
    display: block;
    position: absolute;
    left: 10px;
    right:10px;
    height: 20px
  }

  .c-range-select__selected {
    content: '';
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, $rangeSelectedColor 45%, $rangeSelectedColor 55%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 100%);
    display: block;
    position: absolute;
    left: 10px;
    right:10px;
    height: 20px
  }


  .c-range-select__range::before {
    content: '';
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, $rangeSelectedColor 45%, $rangeSelectedColor 55%, rgba(255,255,255,1) 55%, rgba(255,255,255,1) 100%);
    display: block;
    position: absolute;
    left: 10px;
    right:10px;
    height: 20px
  }

  .c-range-select__range {
    margin-left: 10px;
    margin-right: 10px;
    position: relative;

    height: 20px;
  }

  .c-range-select__range_from,
  .c-range-select__range_to {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #bc1c1c;
    border-radius: 50%;
  }

}