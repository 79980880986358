body.is-gallery-active {
  overflow: hidden;
}

.c-product-gallery {
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom: 0;

  z-index: 200;
  background-color: #fff;

  display: flex;
  flex-direction: column;

  .c-product-gallery__close {

    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      height: 32px;
      width: 32px;
      color: #000;
      pointer-events: none;
    }
  }

  .c-product-gallery__image {
    background-color: #fff;
    flex-grow: 1;
    overflow: hidden;

    .c-product-gallery__image_bg {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      transition: all 0.2s ease-in-out;
      cursor: zoom-in;

      &.is-zoom {
        width: 300%;
        height: 300%;
        cursor: zoom-out;
      }

      &.is-non-transition {
        transition: none !important;
      }
    }
  }

  .c-product-gallery__thumbs {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 90px;

    display: flex;
    align-items: center;
    justify-content: center;

    .c-product-gallery__thumbs__item {
      display: block;
      height: 90px;
      width: 90px;
      border: 1px solid #eee;
      text-align: center;
      padding: 5px;
      margin: 10px 10px;

      &.is-selected {
        border: 1px solid #333;
      }
    }

  }
}