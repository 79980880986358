@use "sass:math";

$cIconLinkHeight: 40px;

$cIconHeight: 32px;

.iconlink {
  position: relative;

  text-align: center;
  display: flex;
  align-items: center;
  height: $cIconLinkHeight;

  padding-left: math.div($cIconLinkHeight - $cIconHeight, 2);
  //padding-left: ($cIconLinkHeight - $cIconHeight) / 2;

  padding-right: math.div($cIconLinkHeight - $cIconHeight, 2);
  //padding-right: ($cIconLinkHeight - $cIconHeight) / 2;

  svg {
    color: #333;
    height: $cIconHeight;
    width: $cIconHeight;
  }

  &:hover {
    svg {
      color: #bc1c1c;
    }
  }
}

.svgbutton {

  border:0;
  background: none;
  outline: 0;


  svg {
    color: #333;
    height: $cIconHeight;
    width: $cIconHeight;
  }

}